import React, { useEffect, useState, useContext } from "react";
import Shield from "../assets/svg/logo.svg"

export default function Loading() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('overflow-y-hidden');
        return () => {
            document.body.classList.remove('overflow-y-hidden');
        };
    }, []);
    return (
        <>
            <div className="w-screen absolute h-full" style={{ zIndex: "100", backgroundColor: "#00000091" }}>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={Shield} alt="Loader" className="opacity-80 pulse" />
                </div>
            </div>
        </>
    )
}
