export const esp = {
  _LOGIN: {
    _TITLE: "Entra en la arena",
    _SUBTITLE: "Ingresa tu correo electrónico y contraseña para iniciar sesión",
    _EMAIL: "Tu correo electrónico",
    _PASSWORD: "Tu contraseña",
    _REMEMBER: "Recuérdame",
    _LOGIN: "Iniciar sesión",
    _SIGNUP: "Regístrate",
    _FORGOTPASSWORD: "¿Olvidaste tu contraseña?",
    _NOACCOUNT: "¿No tienes una cuenta?",
    _ERROR_EMAIL: "¡Aparentemente no estás registrado! Inténtalo de nuevo.",
    _ERROR_PASSWORD: "Contraseña incorrecta",
    _SUCCESS: "Inicio de sesión exitoso",
  },
  _REGISTER: {
    _REGISTER: "Registrar cuenta",
    _NAME: "Tu nombre",
    _EMAIL: "Tu correo electrónico",
    _PASSWORD: "Tu contraseña",
    _CONFIRMPASSWORD: "Confirmar contraseña",
    _REFERAL: "Código de referencia",
    _HAVEACCOUNT: "Tengo una cuenta",
    _ERROR_REFERAL: "Código de referencia incorrecto",
    _ERROR_EMAIL: "Este correo electrónico ya existe",
    _SUCCESS: "Registro exitoso",
  },
  _SIDEBAR: {
    _PAGES: "Páginas",
    _OPEN: "Abrir barra lateral",
    _DASHBOARD: "Tablero",
    _NETWORK: "Red",
    _FINANCE: "Finanzas",
    _PACKAGES: "Paquetes",
    _SIGNOUT: "Cerrar sesión",
    _LANGUAGE: "Idioma",
    _PROFILE: "Perfil",
    _LANGUAGEENGLISH: "Inglés",
    _LANGUAGESPANISH: "Español",
    _LANGUAGEFRENCH: "Francés",
    _LANGUAGEGERMAN: "Alemán",
    _LANGUAGEPORTUGUESE: "Portugués",
    _LANGUAGECHINESE: "Chino",
    _LANGUAGEHINDI: "Hindi",
    _LANGUAGERUSSIAN: "Ruso",
    _LANGUAGEITALIAN: "Italiano",
  },
  _FINANCE: {
    _DEPOSIT: "Depósito",
    _QR: "Escanea el siguiente código QR o copia la dirección",
    _NETWORK: "Red",
    _INSTRUCTIONS: "Instrucciones para enviar TRON (TRX)",
    _STEP1: "Asegúrate de que la red seleccionada sea TRON (TRC20).",
    _STEP2:
      "Envía la cantidad de TRON (TRX) a la siguiente dirección de billetera.",
    _COPY: "Copiar",
    _DISCLAIMER:
      "Descargo de responsabilidad: No somos responsables de las transacciones realizadas fuera de la red TRON (TRC20) o de enviar criptomonedas distintas de TRON (TRX). Cualquier pérdida de fondos debido a estos errores será responsabilidad exclusiva del remitente.",
    _TABLE1: "Historial de depósitos",
    _TABLE1_A: "Orden",
    _TABLE1_B: "Cantidad",
    _TABLE1_C: "Fecha",
    _TABLE1_D: "Estado",
    _SUCCESS: "El paquete se agregó exitosamente",
    _COPYMESSAGE: "Dirección de billetera copiada exitosamente",
  },
  _PACKAGES: {
    _ATTENTION:
      "Atención: Tienes una cuenta corporativa. Al comprar un paquete, reiniciarás la cuenta y todos sus beneficios.",
    _PACKAGES: "Selecciona el mejor plan para ti",
    _SUBTITLE: "Tienes 8 opciones",
    _BALANCE: "Depósito de saldo",
    _PACKAGE: "Paquete",
    _PACKAGE_1: "Precio",
    _PACKAGE_2: "Binario",
    _PACKAGE_3: "ROI diario",
    _BUTTON1: "Completo",
    _BUTTON2: "Comprar",
    _RECHARGE: "No tienes suficiente dinero, recarga tus finanzas",
    _PACKAGEMESSAGE: "Debes comprar un paquete superior",
    _SUCCESS: "El paquete se agregó exitosamente",
  },
  _NETWORK: {
    _LEFT: "Izquierda",
    _RIGHT: "Derecha",
    _AVAILABLE: "Disponible",
    _WITHDRAW: "Total histórico",
    _SEARCHLABEL: "Buscador por email",
    _SEARCH: "Buscar",
    _NETWORK: "Red",
    _DIRECTS: "Directos",
    _SUCCESS: "Busqueda exitosa",
    _ERROR: "No pertenece a tu arbol binario.",
  },
  _PROFILE: {
    _TITLE: "Perfil",
    _NAME: "Tu nombre",
    _EMAIL: "Tu correo electrónico",
    _CURRENTPASSWORD: "Contraseña actual",
    _PASSWORD: "Nueva contraseña",
    _CONFIRMPASSWORD: "Confirmar nueva contraseña",
    _WALLET: "Dirección de retiro",
    _ACTION: "Editar",
    _TIPS: "Consejos",
    _TIPS_1: "El nombre debe ser legible y no superar los 100 caracteres.",
    _TIPS_2:
      "La contraseña debe incluir números, letras y símbolos; cuanto más compleja, mejor.",
    _TIPS_3:
      "El correo electrónico no puede ser cambiado. Si necesitas hacer un cambio, por favor escribe a soporte.",
    _TIPS_4:
      "La dirección de retiro solo se puede modificar con un autenticador de 2 factores, que debe estar habilitado previamente.",
    _ERROR_PASSWORD: "Error en la contraseña anterior",
    _SUCCESS: "Perfil actualizado exitosamente",
    _MIN: "Minimo de retiro",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "¿Olvidaste tu contraseña?",
    _EMAIL: "Tu correo electrónico",
    _BUTTON: "Enviar correo",
    _MESSAGE: "Tu contraseña fue enviada a tu correo electrónico",
    _ERROR_EMAIL: "Este correo electrónico no está registrado",
  },
  _DASHBOARD: {
    _TITLE: "Saldo de depósito",
    _TITLE2: "Ganancias totales de Gladiaverse",
    _TITLE3: "Ganancias del paquete",
    _TITLE4: "Ganancias por binario",
    _WELCOME: "Bienvenido de nuevo",
    _WELCOME2: "Asegura tu éxito en el arbitraje en 4 simples pasos",
    _STEP1: "Configura tu billetera de retiro",
    _STEP2: "Compra un paquete",
    _STEP3: "Recibe tus ganancias",
    _STEP4: "Expande tu red",
    _TABLE2: "Seguimiento de referencias",
    _LEFT: "Izquierda",
    _RIGHT: "Derecha",
    _LEFT_BINARY: "Binario izquierdo",
    _RIGHT_BINARY: "Binario derecho",
    _RANDOM_BINARY: "Binario aleatorio",
    _TABLE3: "Lista de paquetes",
    _START: "Inicio",
    _FINISH: "Fin",
    _STATUS: "Estado",
    _STATUS_ACTIVE: "Activo",
    _STATUS_INACTIVE: "Inactivo",
    _CURRENT: "Actual",
    _UPGRADE: "Actualizar",
    _COPYMESSAGE: "Copiado exitosamente",
    _NOTIFICATION3: "Ganancia de binario",
    _NOTIFICATION2: "Ganancia de arbitraje",
    _NOTIFICATION1: "Nuevo depósito",
    _NOTIFICATION5: "Compra de paquete",
    _SHOW: "Mostrando",
    _SHOW2: "de",
    _PREV: "Anterior",
    _NEXT: "Siguiente",
    _TABLE4_A: "Operación",
    _TABLE4_B: "Ganancia",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Fecha",
  },
  _VERSE: {
    _QUESTION: "Pregunta",
    _ANSWER: "Respuesta",
    _EMPTY: "Selecciona una pregunta para ver los detalles.",
    _INPUT: "Escribe aquí tu pregunta...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Cambiar billetera de retiro",
    _TITLE: "Paso 1",
    _TITLE2: "Paso 2",
    _TITLE3: "Paso 3",
    _STEP1: "Descargar e instalar el autenticador en su dispositivo",
    _STEP2: "Escanear el código para guardar",
    _FORMEMAIL: "Verificación por correo electrónico",
    _FORMHELPEMAIL: "Ingrese el código de verificación de 6 dígitos enviado a",
    _FORMHELPAUTH:
      "Ingrese el código de 6 dígitos generado por la aplicación de autenticación",
    _FORM1: "PIN del autenticador",
    _FORM2: "Dirección de retiro",
    _FORMMIN: "La dirección de retiro debe tener 34 caracteres de longitud",
    _FORMCODESEND: "Enviar código",
    _VERIFF: "Verificar",
    _ACTION: "Verificar y Guardar",
    _ERROR: "Error, inténtelo de nuevo.",
    _SUCCESS: "Cambio exitoso",
    _RETRYOTP: "Error al enviar el código, inténtelo de nuevo.",
    _SENDOTP: "Código enviado correctamente",
    _SUCCESSOTP: "OTP verificado satisfactoriamente",
    _SUCCESSAUTH2F: "Auth2fa verificado satisfactoriamente",
    _ERRORCODE: "Código inválido, inténtelo de nuevo",
  },
  _COUNTDOWN: {
    _TITLE: "Próximo corte:",
    _DAYS: "Días",
    _HOURS: "Horas",
    _MINUTES: "Minutos",
    _SECONDS: "Segundos",
  },
  _WITHDRAWAL: {
    _TITLE: "Transacciones de Retiro",
  },
};
