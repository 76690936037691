import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { eng } from "./en";
import { esp } from "./es";
import { fra } from "./fr";
import { ger } from "./ge";
import { por } from "./pr";
import { chi } from "./ch";
import { hin } from "./hn";
import { rus } from "./rs";
import { ita } from "./it";
import { vie } from "./vi";
import { tha } from "./th";
import { fil } from "./fi";

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: eng,
    },
    Spanish: {
      translation: esp,
    },
    French: {
      translation: fra,
    },
    German: {
      translation: ger,
    },
    Portuguese: {
      translation: por,
    },
    Chinese: {
      translation: chi,
    },
    Hindi: {
      translation: hin,
    },
    Russian: {
      translation: rus,
    },
    Italian: {
      translation: ita,
    },
    Vietnamese: {
      translation: vie,
    },
    Tailanda: {
      translation: tha,
    },
    Filipino: {
      translation: fil,
    },
  },
  lng: localStorage.getItem("lng") || "English",
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
