export const rus = {
  _LOGIN: {
    _TITLE: "Войти в арену",
    _SUBTITLE: "Введите свою электронную почту и пароль для входа",
    _EMAIL: "Ваша электронная почта",
    _PASSWORD: "Ваш пароль",
    _REMEMBER: "Запомнить меня",
    _LOGIN: "Вход",
    _SIGNUP: "Зарегистрироваться",
    _FORGOTPASSWORD: "Забыли пароль?",
    _NOACCOUNT: "У вас нет аккаунта?",
    _ERROR_EMAIL: "Похоже, вы не зарегистрированы! Попробуйте еще раз.",
    _ERROR_PASSWORD: "Неправильный пароль",
    _SUCCESS: "Успешный вход",
  },
  _REGISTER: {
    _REGISTER: "Зарегистрировать аккаунт",
    _NAME: "Ваше имя",
    _EMAIL: "Ваша электронная почта",
    _PASSWORD: "Ваш пароль",
    _CONFIRMPASSWORD: "Подтвердите пароль",
    _REFERAL: "Реферальный код",
    _HAVEACCOUNT: "У меня уже есть аккаунт",
    _ERROR_REFERAL: "Неверный реферальный код",
    _ERROR_EMAIL: "Этот адрес электронной почты уже существует",
    _SUCCESS: "Успешная регистрация",
  },
  _SIDEBAR: {
    _PAGES: "Страницы",
    _OPEN: "Открыть боковую панель",
    _DASHBOARD: "Панель управления",
    _NETWORK: "Сеть",
    _FINANCE: "Финансы",
    _PACKAGES: "Пакеты",
    _SIGNOUT: "Выйти",
    _LANGUAGE: "Язык",
    _PROFILE: "Профиль",
    _LANGUAGEENGLISH: "Английский",
    _LANGUAGESPANISH: "Испанский",
    _LANGUAGEFRENCH: "Французский",
    _LANGUAGEGERMAN: "Немецкий",
    _LANGUAGEPORTUGUESE: "Португальский",
    _LANGUAGECHINESE: "Китайский",
    _LANGUAGEHINDI: "Хинди",
    _LANGUAGERUSSIAN: "Русский",
    _LANGUAGEITALIAN: "Итальянский",
  },
  _FINANCE: {
    _DEPOSIT: "Депозит",
    _QR: "Отсканируйте следующий QR-код или скопируйте адрес",
    _NETWORK: "Сеть",
    _INSTRUCTIONS: "Инструкции по отправке TRON (TRX)",
    _STEP1: "Убедитесь, что выбрана сеть TRON (TRC20).",
    _STEP2: "Отправьте сумму TRON (TRX) на следующий адрес кошелька.",
    _COPY: "Копировать",
    _DISCLAIMER:
      "Отказ от ответственности: Мы не несем ответственности за транзакции, совершенные вне сети TRON (TRC20) или за отправку криптовалют, отличных от TRON (TRX). Любая потеря средств из-за этих ошибок будет лежать исключительно на отправителе.",
    _TABLE1: "История депозитов",
    _TABLE1_A: "Заказ",
    _TABLE1_B: "Сумма",
    _TABLE1_C: "Дата",
    _TABLE1_D: "Статус",
    _SUCCESS: "Пакет был успешно добавлен",
    _COPYMESSAGE: "Адрес кошелька успешно скопирован",
  },
  _PACKAGES: {
    _ATTENTION:
      "Внимание: У вас корпоративный аккаунт. При покупке пакета вы перезапустите аккаунт и все его преимущества.",
    _PACKAGES: "Выберите лучший план для вас",
    _SUBTITLE: "У вас есть 8 вариантов",
    _BALANCE: "Баланс депозита",
    _PACKAGE: "Пакет ",
    _PACKAGE_1: "Цена",
    _PACKAGE_2: "Бинарный",
    _PACKAGE_3: "Ежедневный ROI",
    _BUTTON1: "Заполнено",
    _BUTTON2: "Купить",
    _RECHARGE: "У вас недостаточно средств, пополните свои финансы",
    _PACKAGEMESSAGE: "Вы должны приобрести более высокий пакет",
    _SUCCESS: "Пакет был успешно добавлен",
  },
  _NETWORK: {
    _LEFT: "Лево",
    _RIGHT: "Право",
    _AVAILABLE: "Доступно",
    _WITHDRAW: "Общий вывод",
    _SEARCHLABEL: "Поиск по email",
    _SEARCH: "Поиск",
    _NETWORK: "Сеть",
    _DIRECTS: "Прямые",
    _SUCCESS: "Поиск успешен",
    _ERROR: "Не является частью вашего бинарного дерева.",
  },
  _PROFILE: {
    _TITLE: "Профиль",
    _NAME: "Ваше имя",
    _EMAIL: "Ваша электронная почта",
    _CURRENTPASSWORD: "Текущий пароль",
    _PASSWORD: "Новый пароль",
    _CONFIRMPASSWORD: "Подтвердите новый пароль",
    _WALLET: "Адрес вывода",
    _ACTION: "Редактировать",
    _TIPS: "Советы",
    _TIPS_1: "Имя должно быть четким и не превышать 100 символов.",
    _TIPS_2:
      "Пароль должен включать в себя цифры, буквы и символы; чем сложнее, тем лучше.",
    _TIPS_3:
      "Адрес электронной почты нельзя изменить. Если вам нужно внести изменения, напишите в службу поддержки.",
    _TIPS_4:
      "Адрес вывода можно изменить только с помощью двухфакторной аутентификации, которую необходимо предварительно включить.",
    _ERROR_PASSWORD: "Ошибка предыдущего пароля",
    _SUCCESS: "Профиль успешно обновлен",
    _MIN: "Минимальная сумма вывода",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Забыли пароль?",
    _EMAIL: "Ваша электронная почта",
    _BUTTON: "Отправить письмо",
    _MESSAGE: "Ваш пароль был отправлен на вашу электронную почту",
    _ERROR_EMAIL: "Этот адрес электронной почты не зарегистрирован",
  },
  _DASHBOARD: {
    _TITLE: "Баланс депозита",
    _TITLE2: "Общая прибыль Gladiaverse",
    _TITLE3: "Доход от пакетов",
    _TITLE4: "Прибыль по бинарному плану",
    _WELCOME: "Добро пожаловать назад",
    _WELCOME2: "Обеспечьте свой успех в арбитраже в 4 простых шага",
    _STEP1: "Настройте свой кошелек для вывода",
    _STEP2: "Приобретите пакет",
    _STEP3: "Получите свою прибыль",
    _STEP4: "Расширьте свою сеть",
    _TABLE2: "Отслеживание рефералов",
    _LEFT: "Левый",
    _RIGHT: "Правый",
    _LEFT_BINARY: "Левый бинар",
    _RIGHT_BINARY: "Правый бинар",
    _RANDOM_BINARY: "Случайный бинар",
    _TABLE3: "Список пакетов",
    _START: "Начало",
    _FINISH: "Завершение",
    _STATUS: "Статус",
    _STATUS_ACTIVE: "Активен",
    _STATUS_INACTIVE: "Неактивен",
    _CURRENT: "Текущий",
    _UPGRADE: "Обновить",
    _COPYMESSAGE: "Копирование успешно",
    _NOTIFICATION3: "Бинарная прибыль",
    _NOTIFICATION2: "Арбитражная прибыль",
    _NOTIFICATION1: "Новый депозит",
    _NOTIFICATION5: "Покупка пакета",
    _SHOW: "Показано",
    _SHOW2: "из",
    _PREV: "Предыдущий",
    _NEXT: "Следующий",
    _TABLE4_A: "Операция",
    _TABLE4_B: "Прибыль",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Дата",
  },
  _VERSE: {
    _QUESTION: "Вопрос",
    _ANSWER: "Ответ",
    _EMPTY: "Выберите вопрос, чтобы увидеть детали.",
    _INPUT: "Напишите здесь ваш вопрос...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Изменить кошелек для вывода",
    _TITLE: "Шаг 1",
    _TITLE2: "Шаг 2",
    _TITLE3: "Шаг 3",
    _STEP1: "Скачайте и установите аутентификатор на вашем устройстве",
    _STEP2: "Отсканируйте код для сохранения",
    _FORMEMAIL: "Подтверждение по электронной почте",
    _FORMHELPEMAIL: "Введите 6-значный проверочный код, отправленный на",
    _FORMHELPAUTH:
      "Введите 6-значный код, сгенерированный приложением аутентификатора",
    _FORM1: "ПИН-код аутентификатора",
    _FORM2: "Адрес вывода",
    _FORMMIN: "Адрес вывода должен быть длиной 34 символа",
    _FORMCODESEND: "Отправить код",
    _VERIFF: "Проверить",
    _ACTION: "Проверить и сохранить",
    _ERROR: "Ошибка, попробуйте снова.",
    _SUCCESS: "Успешное изменение",
    _RETRYOTP: "Ошибка отправки кода, попробуйте снова.",
    _SENDOTP: "Код успешно отправлен",
    _SUCCESSOTP: "OTP успешно подтвержден",
    _SUCCESSAUTH2F: "Auth2fa успешно подтвержден",
    _ERRORCODE: "Неверный код, попробуйте снова",
  },
  _COUNTDOWN: {
    _TITLE: "Следующее сокращение:",
    _DAYS: "Дни",
    _HOURS: "Часы",
    _MINUTES: "Минуты",
    _SECONDS: "Секунды",
  },
  _WITHDRAWAL: {
    _TITLE: "Транзакции по снятию",
  },
};
