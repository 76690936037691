import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";
import Package500 from "../assets/svg/500.svg"
import Package1000 from "../assets/svg/1000.svg"
import Package2000 from "../assets/svg/2000.svg"
import Package4000 from "../assets/svg/4000.svg"
import Package8000 from "../assets/svg/8000.svg"
import Package16000 from "../assets/svg/16000.svg"
import Package32000 from "../assets/svg/32000.svg"
import Package64000 from "../assets/svg/64000.svg"
import CustomButton from '../components/CustomButton';

export default function Packages() {
  const { t } = useTranslation();
  const [balance, setBalance] = useState([]);
  const { loading, updateLoading } = useContext(AppContext);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getUsersData();
  }, []);

  const items = [
    { cost: 500, binary: 6, daily: '0.3%', image: Package500 },
    { cost: 1000, binary: 7, daily: 'hasta 2.1%', image: Package1000 },
    { cost: 2000, binary: 9, daily: 'hasta 2.1%', image: Package2000 },
    { cost: 4000, binary: 11, daily: 'hasta 2.1%', image: Package4000 },
    { cost: 8000, binary: 13, daily: 'hasta 2.1%', image: Package8000 },
    { cost: 16000, binary: 15, daily: 'hasta 2.1%', image: Package16000 },
    { cost: 32000, binary: 17, daily: 'hasta 2.1%', image: Package32000 },
    { cost: 64000, binary: 21, daily: 'hasta 2.1% + 0.3%', image: Package64000 },
  ];

  const handlePurchase = (position) => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("packages_type", position);
    fetch(`${process.env.REACT_APP_BACKEND}/packages`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          if (response.balance === false) {
            toast.error(t("_PACKAGES._RECHARGE"));
          }
          if (response.package === false) {
            toast.error(t("_PACKAGES._PACKAGEMESSAGE"));
          }
          updateLoading(false);
        } else {
          toast.success(t("_PACKAGES._SUCCESS"));
          getUsersData();
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const getUsersData = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setUserData(response?.data)
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  return (
    <div className="mx-auto lg:p-4">
      <div className="bg-gladiaverse p-6 rounded-lg ">
        {userData?.is_corporate === "1" && (
          <div className="w-full my-2 mx-auto">
            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 p-2 my-2 rounded dark:bg-red-900 dark:text-red-300 ">{t("_PACKAGES._ATTENTION")}</span>
          </div>
        )}
        <div className="flex flex-col lg:flex-row justify-between mb-2">
          <div>
            <h3 className="text-lg text-white">{t("_PACKAGES._PACKAGES")}</h3>
            <p className="text-sm text-slate-400">{t("_PACKAGES._SUBTITLE")}</p>
          </div>
          <div>
            <h3 className="text-lg text-white">{t("_PACKAGES._BALANCE")}</h3>
            <p className="text-lg text-white">{userData?.balance_deposit} TRX</p>
          </div>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {items.map((item, index) => (
            <div
              key={index}
              className="w-full "
            >
              <div className="rounded-lg text-white carta-box lg:min-h-96 flex flex-col">
                <div className="carta h-96 flex z-10">
                  <div className="cara w-full min-h-min text-center" >
                    <img src={item.image} alt={`Package ${item.cost}`} className="cursor-pointer mx-auto" />
                  </div>
                  <div className="cara w-full detras p-10">
                    <div className="bg-slate-900 rounded-lg p-2 min-h-72 text-center">
                      <h2 className="text-white mb-4">{t("_PACKAGES._PACKAGE")} {index + 1}</h2>
                      <div className="flex flex-col  items-center justify-center mb-2 space-y-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                          <path stroke-linecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                        </svg>
                        <p className="text-white">{t("_PACKAGES._PACKAGE_1")}: {item.cost} TRX</p>
                      </div>
                      <div className="flex flex-col items-center justify-center mb-2 space-y-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                          <path stroke-linecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                        </svg>
                        <p className="text-white">{t("_PACKAGES._PACKAGE_2")}: {item.binary}%</p>
                      </div>
                      <div className="flex flex-col  items-center justify-center mb-2 space-y-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                          <path stroke-linecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" />
                        </svg>
                        <p className="text-white">{t("_PACKAGES._PACKAGE_3")}: {item.daily}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-10 -mt-10 z-40">
                  {parseInt(userData?.package) <= parseInt(index + 1) ? <>
                    <button onClick={() => handlePurchase(index + 1)} className="text-md w-full border-2 p-1 cursor-pointer rounded-lg" >{t("_PACKAGES._BUTTON2")}</button>
                  </>
                    :
                    <>
                      <button className="text-md w-full border-2 p-1 cursor-pointer rounded-lg opacity-20" disabled> {t("_PACKAGES._BUTTON1")}</button>
                    </>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
