import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import LogoGladiverse from "../assets/svg/logo.svg"
import GladiaverseBottom from "../assets/svg/bottom.svg"
import GladiaverseTop from "../assets/svg/top.svg"
import AppContext from "../context/AppContext";
import CustomButton from '../components/CustomButton';
import toast from "react-hot-toast";

export default function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors }, setValue, control, reset } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedValue, setSelectedValue] = useState('20');
  const [selectedValueHistory, setSelectedValueHistory] = useState('20');
  const { loading, updateLoading } = useContext(AppContext);
  const [userData, setUserData] = useState([]);


  useEffect(() => {
    getUsersData()
  }, []);

  const onSubmit = data => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("password_old", data?.password_old);
    formData.append("password", data?.password);
    fetch(`${process.env.REACT_APP_BACKEND}/update-profile`, {
      method: 'POST',
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (!response.status) {
          if (response?.passwordOld === true) {
            toast.error(t("_PROFILE._ERROR_PASSWORD"));
          }
          updateLoading(false);
        } else {
          reset();
          toast.success(t("_PROFILE._SUCCESS"));
          getUsersData();
        }
      })
      .catch((error) => {
        updateLoading(false);
        console.error('Error:', error);
      });
  };


  const getUsersData = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setUserData(response?.data);
        setValue("name", response?.data?.name);
        setValue("email", response?.data?.email);
        setValue("withdrawalAddress", response?.data?.withdrawal_address);
        setSelectedValue(response?.data?.withdrawal_min || 20)
        setSelectedValueHistory(response?.data?.withdrawal_min || 20)
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const changeMin = data => {
    updateLoading(true);
    // Map the selectedValue to the corresponding type value
    let typeValue;
    switch (selectedValue) {
      case "20":
        typeValue = 1;
        break;
      case "100":
        typeValue = 2;
        break;
      case "500":
        typeValue = 3;
        break;
      default:
        typeValue = 1; // Fallback to the original value if no match is found
    }

    if (typeValue === selectedValueHistory) {
      toast.error(t("_PROFILE._ERROR_PASSWORD"));
      updateLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("type", typeValue);
    fetch(`${process.env.REACT_APP_BACKEND}/update-min`, {
      method: 'POST',
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (!response.status) {
          if (response?.passwordOld === true) {
            toast.error(t("_PROFILE._ERROR_PASSWORD"));
          }
          updateLoading(false);
        } else {
          reset();
          toast.success(t("_PROFILE._SUCCESS"));
          getUsersData();
        }
      })
      .catch((error) => {
        updateLoading(false);
        console.error('Error:', error);
      });
  };


  const formValues = useWatch({ control });

  const isFormChanged = () => {
    return (
      formValues.name !== userData?.name ||
      formValues.email !== userData?.email ||
      formValues.password_old ||
      formValues.newPassword ||
      formValues.confirmPassword
    );
  };


  const handleSelect = (value) => {
    setSelectedValue(value);
  };

  return (
    <div className="container mx-auto h-screen">
      <div className="flex flex-col lg:flex-row gap-4 p-4">
        <div className="flex lg:flex-row flex-col relative h-auto gap-10 text-white">
          <div className="w-full lg:w-4/5 space-y-1 bg-gladiaverse p-6 rounded-lg shadow mx-auto relative">
            <h1 className="text-xl">{t("_PROFILE._TITLE")}</h1>
            <form className="mt-8 space-y-3 z-40 w-full lg:w-9/12" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-white mb-2">{t("_PROFILE._NAME")}</label>
                <input
                  id="name"
                  type="text"
                  {...register('name', {
                    required: 'Name is required',
                    maxLength: { value: 100, message: 'Name cannot exceed 100 characters' }
                  })}
                  className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4 z-40"
                />
                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_PROFILE._EMAIL")}</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4 z-40"
                  {...register('email', {
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email address'
                    }
                  })}
                  disabled
                />
                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
              </div>
              <div>
                <label htmlFor="password_old" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_PROFILE._CURRENTPASSWORD")}</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password_old"
                  id="password_old"
                  className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4 z-40"
                  {...register('password_old', {
                    minLength: { value: 6, message: 'Password must have at least 6 characters' },
                    pattern: {
                      value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/,
                      message: 'Password must contain at least one letter and one number'
                    }
                  })}
                />
                {errors.password_old && <p className="text-red-500 text-xs mt-1">{errors.password_old.message}</p>}
              </div>
              <div>
                <label htmlFor="newPassword" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_PROFILE._PASSWORD")}</label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="newPassword"
                    id="newPassword"
                    className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
                    {...register('password', {
                      minLength: { value: 6, message: 'Password must have at least 6 characters' },
                      pattern: {
                        value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/,
                        message: 'Password must contain at least one letter and one number',
                      },
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    {showPassword ? (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-6" onClick={() => setShowPassword(!showPassword)}>
                        <path stroke-linecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                        <path stroke-linecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="size-6" onClick={() => setShowPassword(!showPassword)}>
                        <path stroke-linecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                      </svg>
                    )}
                  </div>
                </div>
                {errors.newPassword && <p className="text-red-500 text-xs mt-1">{errors.newPassword.message}</p>}
              </div>
              <div>
                <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_PROFILE._CONFIRMPASSWORD")}</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
                  {...register('confirmPassword', {
                    validate: value => value === watch('password') || 'Passwords do not match'
                  })}
                />
                {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword.message}</p>}
              </div>
              <div>
                <label htmlFor="withdrawalAddress" className="block text-sm font-medium mb-2 text-white">{t("_PROFILE._WALLET")}</label>
                <div className="flex items-center gap-2">
                  <input
                    id="withdrawalAddress"
                    type="text"
                    disabled
                    {...register('withdrawalAddress')}
                    className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4 z-40"
                  />
                  <button
                    type="button"
                    className="text-md px-2 border-2 p-1 cursor-pointer rounded-lg z-40"
                    onClick={() => navigate('/auth')}
                  >
                    {t("_PROFILE._ACTION")}
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="withdrawalAddress" className="block text-sm font-medium mb-2 text-white">{t("_PROFILE._MIN")}</label>
                <div className="flex flex-col lg:flex-row gap-2">
                  <ul className="items-center w-full text-sm font-medium text-gray-900 bg-gladiaverse border border-gladiaverse rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    <li className="w-full border-b sm:border-b-0 sm:border-r">
                      <div className="flex items-center ps-3" onClick={() => handleSelect('20')}>
                        <input
                          name="min-withdrawal"
                          type="checkbox"
                          checked={selectedValue === '20'}
                          onChange={() => handleSelect('20')}
                          className="w-4 h-4 rounded focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"
                        />
                        <label htmlFor="vue-checkbox-list" className="w-full py-3 ms-2 text-sm font-medium text-gray-300">20 TRX</label>
                      </div>
                    </li>
                    <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                      <div className="flex items-center ps-3" onClick={() => handleSelect('100')}>
                        <input
                          name="min-withdrawal"
                          type="checkbox"
                          checked={selectedValue === '100'}
                          onChange={() => handleSelect('100')}
                          className="w-4 h-4 rounded focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"
                        />
                        <label htmlFor="react-checkbox-list" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">100 TRX</label>
                      </div>
                    </li>
                    <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                      <div className="flex items-center ps-3" onClick={() => handleSelect('500')}>
                        <input
                          name="min-withdrawal"
                          type="checkbox"
                          checked={selectedValue === '500'}
                          onChange={() => handleSelect('500')}
                          className="w-4 h-4 rounded focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"
                        />
                        <label htmlFor="angular-checkbox-list" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">500 TRX</label>
                      </div>
                    </li>
                  </ul>
                  <button
                    type="button"
                    className={`text-md px-2 border-2 p-1 cursor-pointer rounded-lg z-40 ${selectedValue === selectedValueHistory ? 'opacity-10 cursor-not-allowed' : ''
                      }`}
                    disabled={selectedValue === selectedValueHistory}
                    onClick={() => changeMin()}
                  >
                    {t("_PROFILE._ACTION")}
                  </button>
                </div>
              </div>
              <CustomButton text="Update Profile" isSubmit={true} disabled={!isFormChanged() || loading} fontSize={"text-1xl"} />
            </form>
            <img src={GladiaverseBottom} className="absolute bottom-0 right-0 w-30 opacity-10 lg:w-60 lg:opacity-100 z-10 hidden xl:block h-auto" alt={"Gladiaverse"} />
            <img src={GladiaverseTop} className="absolute top-0 right-0 lg:w-60 hidden z-10 xl:block" alt={"Gladiaverse Top"} />
          </div>
          <div className="w-full lg:w-1/2 space-y-1 bg-gladiaverse p-6 rounded-lg shadow flex flex-col justify-between items-center">
            <div>
              <h1 className="text-xl my-10">{t("_PROFILE._TIPS")}</h1>
              <ul className="list-disc pl-5 space-y-2 text-white">
                <li>{t("_PROFILE._TIPS_1")}</li>
                <li>{t("_PROFILE._TIPS_2")}</li>
                <li>{t("_PROFILE._TIPS_3")}</li>
                <li>{t("_PROFILE._TIPS_4")}</li>
              </ul>
            </div>
            <img src={LogoGladiverse} alt={"Gladiaverse"} className="lg:w-60  my-20" />
          </div>
        </div>
      </div>
    </div >
  )
}
