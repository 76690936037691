import React from 'react';
import { Treemap, ResponsiveContainer } from 'recharts';

const TreemapChart = ({ data }) => {
    // Transforma los datos para que sean compatibles con el Treemap
    const transformedData = data && data.map(item => ({
        name: item.sellExchange,
        size: parseInt(item.operation_count, 10)
    }));

    return (
        <ResponsiveContainer width="100%" height={500}>
            <Treemap
                data={transformedData}
                dataKey="size"
                ratio={4 / 3}
                stroke="#fff"
                fill="#187f30"
            />
        </ResponsiveContainer>
    );
};

export default TreemapChart;
