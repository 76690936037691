export const fil = {
  _LOGIN: {
    _TITLE: "Pumasok sa arena",
    _SUBTITLE: "Ilagay ang iyong email at password upang mag-sign in",
    _EMAIL: "Iyong email",
    _PASSWORD: "Iyong password",
    _REMEMBER: "Tandaan ako",
    _LOGIN: "Mag-login",
    _SIGNUP: "Mag-sign up",
    _FORGOTPASSWORD: "Nakalimutan mo ba ang iyong password?",
    _NOACCOUNT: "Wala ka pang account?",
    _ERROR_EMAIL: "Mukhang hindi ka rehistrado! Subukan muli.",
    _ERROR_PASSWORD: "Maling password",
    _SUCCESS: "Matagumpay na Pag-login",
  },
  _REGISTER: {
    _REGISTER: "Rehistro ng Account",
    _NAME: "Iyong Pangalan",
    _EMAIL: "Iyong email",
    _PASSWORD: "Iyong password",
    _CONFIRMPASSWORD: "Kumpirmahin ang Password",
    _REFERAL: "Referral Code",
    _HAVEACCOUNT: "May account na ako",
    _ERROR_REFERAL: "Maling referral code",
    _ERROR_EMAIL: "Ang email na ito ay mayroon na",
    _SUCCESS: "Matagumpay na Pagpaparehistro",
  },
  _SIDEBAR: {
    _PAGES: "Mga Pahina",
    _OPEN: "Buksan ang sidebar",
    _DASHBOARD: "Dashboard",
    _NETWORK: "Network",
    _FINANCE: "Pinansya",
    _PACKAGES: "Mga Package",
    _SIGNOUT: "Mag-sign Out",
    _LANGUAGE: "Wika",
    _PROFILE: "Profile",
    _LANGUAGEENGLISH: "Ingles",
    _LANGUAGESPANISH: "Español",
    _LANGUAGEFRENCH: "Français",
    _LANGUAGEGERMAN: "Deutsch",
    _LANGUAGEPORTUGUESE: "Portuguese",
    _LANGUAGECHINESE: "Chinese",
    _LANGUAGEHINDI: "Hindi",
    _LANGUAGERUSSIAN: "Russian",
    _LANGUAGEITALIAN: "Italian",
  },
  _FINANCE: {
    _DEPOSIT: "Magdeposito",
    _QR: "I-scan ang sumusunod na QR o kopyahin ang address",
    _NETWORK: "Network",
    _INSTRUCTIONS: "Mga Tagubilin para sa Pagpapadala ng TRON (TRX)",
    _STEP1: "Siguraduhin na ang napiling network ay TRON (TRC20).",
    _STEP2: "Ipadala ang halaga ng TRON (TRX) sa sumusunod na wallet address.",
    _COPY: "Kopyahin",
    _DISCLAIMER:
      "Paunawa: Hindi kami responsable para sa mga transaksyong ginawa sa labas ng TRON (TRC20) network o para sa pagpapadala ng mga cryptocurrency maliban sa TRON (TRX). Anumang pagkawala ng pondo dahil sa mga pagkakamali ay magiging tanging responsibilidad ng nagpadala.",
    _TABLE1: "Kasaysayan ng Deposit",
    _TABLE1_A: "Order",
    _TABLE1_B: "Halaga",
    _TABLE1_C: "Petsa",
    _TABLE1_D: "Status",
    _SUCCESS: "Matagumpay na naidagdag ang package",
    _COPYMESSAGE: "Matagumpay na nakopya ang wallet address",
  },
  _PACKAGES: {
    _ATTENTION:
      "Pansin: Mayroon kang corporate account. Sa pagbili ng package, ire-reset mo ang account at lahat ng mga benepisyo nito.",
    _PACKAGES: "Piliin ang pinakamahusay na plano para sa iyo",
    _SUBTITLE: "Mayroon kang 8 pagpipilian",
    _BALANCE: "Balanseng Deposito",
    _PACKAGE: "Package ",
    _PACKAGE_1: "Presyo",
    _PACKAGE_2: "Binary",
    _PACKAGE_3: "Daily ROI",
    _BUTTON1: "Napuno",
    _BUTTON2: "Bumili",
    _RECHARGE: "Wala kang sapat na pera, mag-recharge ng iyong pinansya",
    _PACKAGEMESSAGE: "Kailangan mong bumili ng mas mataas na package",
    _SUCCESS: "Matagumpay na naidagdag ang package",
  },
  _NETWORK: {
    _LEFT: "Kaliwa",
    _RIGHT: "Kanan",
    _AVAILABLE: "Magagamit",
    _WITHDRAW: "Kabuuang kasaysayan",
    _SEARCHLABEL: "Paghahanap ng email",
    _SEARCH: "Maghanap",
    _NETWORK: "Network",
    _DIRECTS: "Mga Direktang",
    _SUCCESS: "Matagumpay na paghahanap",
    _ERROR: "Hindi bahagi ng iyong binary tree.",
  },
  _PROFILE: {
    _TITLE: "Profile",
    _NAME: "Iyong Pangalan",
    _EMAIL: "Iyong email",
    _CURRENTPASSWORD: "Kasalukuyang password",
    _PASSWORD: "Bagong password",
    _CONFIRMPASSWORD: "Kumpirmahin ang bagong password",
    _WALLET: "Withdrawal Address",
    _ACTION: "I-edit",
    _TIPS: "Mga Tip",
    _TIPS_1: "Dapat mabasa ang pangalan at hindi hihigit sa 100 character.",
    _TIPS_2:
      "Dapat kasama sa password ang mga numero, letra, at simbolo; mas kumplikado, mas mabuti.",
    _TIPS_3:
      "Hindi maaaring baguhin ang email. Kung kailangan mong gumawa ng pagbabago, mangyaring sumulat sa support.",
    _TIPS_4:
      "Ang withdrawal address ay maaari lamang mabago gamit ang 2-factor authenticator, na dapat i-enable nang maaga.",
    _ERROR_PASSWORD: "Maling nakaraang password",
    _SUCCESS: "Matagumpay na na-update ang profile",
    _MIN: "Pienin nostosumma",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Nakalimutan ang iyong password?",
    _EMAIL: "Iyong email",
    _BUTTON: "Magpadala ng email",
    _MESSAGE: "Ang iyong password ay ipinadala sa iyong email",
    _ERROR_EMAIL: "Ang email na ito ay hindi rehistrado",
  },
  _DASHBOARD: {
    _TITLE: "Balanseng Deposito",
    _TITLE2: "Kabuuang kita ng Gladiaverse",
    _TITLE3: "Kita mula sa Package",
    _TITLE4: "Kita mula sa Binary",
    _WELCOME: "Maligayang pagbabalik",
    _WELCOME2:
      "Tiyakin ang iyong tagumpay sa arbitrasyon sa 4 na simpleng hakbang",
    _STEP1: "I-set up ang iyong withdrawal wallet",
    _STEP2: "Bumili ng package",
    _STEP3: "Tanggapin ang iyong mga kita",
    _STEP4: "Palawakin ang iyong network",
    _TABLE2: "Pagsubaybay sa Referral",
    _LEFT: "Kaliwa",
    _RIGHT: "Kanan",
    _LEFT_BINARY: "Kaliwang binary",
    _RIGHT_BINARY: "Kanan binary",
    _RANDOM_BINARY: "Random na binary",
    _TABLE3: "Listahan ng mga Package",
    _START: "Simulan",
    _FINISH: "Tapusin",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Aktibo",
    _STATUS_INACTIVE: "Hindi Aktibo",
    _CURRENT: "Kasalukuyan",
    _UPGRADE: "I-upgrade",
    _COPYMESSAGE: "Matagumpay na pagkopya",
    _NOTIFICATION3: "Kita mula sa Binary",
    _NOTIFICATION2: "Kita mula sa Arbitrasyon",
    _NOTIFICATION1: "Bagong Deposit",
    _NOTIFICATION5: "Pagbili ng Package",
    _SHOW: "Ipinapakita",
    _SHOW2: "ng",
    _PREV: "Nakaraan",
    _NEXT: "Susunod",
    _TABLE4_A: "Operasyon",
    _TABLE4_B: "Kita",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Petsa",
  },
  _VERSE: {
    _QUESTION: "Tanong",
    _ANSWER: "Sagot",
    _EMPTY: "Piliin ang isang tanong upang makita ang mga detalye.",
    _INPUT: "Isulat ang iyong tanong dito...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Baguhin ang withdrawal wallet",
    _TITLE: "Hakbang 1",
    _TITLE2: "Hakbang 2",
    _TITLE3: "Hakbang 3",
    _STEP1: "I-download at i-install ang authenticator sa iyong device",
    _STEP2: "I-scan ang code upang i-save",
    _FORMEMAIL: "Pag-verify ng Email",
    _FORMHELPEMAIL: "Ilagay ang 6-digit na verification code na ipinadala sa",
    _FORMHELPAUTH: "Ilagay ang 6-digit na code na ginawa ng Authenticator App",
    _FORM1: "Pin ng Authenticator",
    _FORM2: "Withdrawal address",
    _FORMMIN: "Dapat 34 na karakter ang haba ng withdrawal address",
    _FORMCODESEND: "Naipadala ang Code",
    _VERIFF: "Veriff",
    _ACTION: "Suriin at I-save",
    _ERROR: "Error, subukan muli.",
    _SUCCESS: "Matagumpay na pagbabago",
    _RETRYOTP: "Error sa pagpapadala ng code, subukan muli.",
    _SENDOTP: "Matagumpay na naipadala ang code",
    _SUCCESSOTP: "Matagumpay na na-verify ang OTP",
    _SUCCESSAUTH2F: "Matagumpay na na-verify ang Auth2fa",
    _ERRORCODE: "Invalid code, subukan muli",
  },
  _COUNTDOWN: {
    _TITLE: "Susunod na cut:",
    _DAYS: "Mga Araw",
    _HOURS: "Mga Oras",
    _MINUTES: "Mga Minuto",
    _SECONDS: "Mga Segundo",
  },
  _WITHDRAWAL: {
    _TITLE: "Nostotapahtumat",
  },
};
