import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import AppContext from "../context/AppContext";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import toast from "react-hot-toast";
import CustomButton from '../components/CustomButton';
import GladiaverseBottom from "../assets/svg/bottom.svg"

export default function Finance() {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState();
  const [listDeposit, setListDeposit] = useState([]);
  const { loading, updateLoading } = useContext(AppContext);

  useEffect(() => {
    getWallet();
    getDepositList();
  }, []);

  const getWallet = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/deposit`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          updateLoading(false);
        } else {
          setWallet(response?.address);
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const getDepositList = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/deposit?limit=40`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === false) {
          updateLoading(false);
        } else {
          setListDeposit(response?.data?.items);
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return formatDistanceToNow(date, { locale: es, addSuffix: true });
  }

  const copyWallet = () => {
    navigator.clipboard.writeText(
      `${wallet}`
    );
    toast.success(t("_FINANCE._COPYMESSAGE"));
  };

  return (
    <div className="mx-auto lg:p-4">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="bg-gladiaverse text-white p-6 rounded-lg w-full lg:w-2/6">
          <h2 className="text-lg font-semibold mb-2">{t("_FINANCE._DEPOSIT")}</h2>
          <p className="text-center text-md mb-4">{t("_FINANCE._QR")}</p>
          <div className="flex justify-center items-center w-full">
            <QRCode
              size={256}
              value={wallet ? wallet : "Loading"}
              className={"w-4/6 bg-gray-800 p-4 rounded-lg"}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
        <div className="bg-gladiaverse p-6 rounded-lg shadow w-full lg:w-4/6 flex flex-col text-left h-auto relative">
          <div className="w-full lg:w-8/12 p-4">
            <h2 className="text-lg text-white ">{t("_FINANCE._NETWORK")}: TRON</h2>
            <p className="text-white">{t("_FINANCE._INSTRUCTIONS")}</p>
            <ul className="text-sm text-white list-disc">
              <li>{t("_FINANCE._STEP1")}</li>
              <li>{t("_FINANCE._STEP2")}</li>
            </ul>
            <br />
            <p className="break-words text-center mb-2 text-xs md:text-sm text-white">
              <span className="bg-gray-800 text-white font-medium me-2 py-3  px-0 lg:p-4 rounded ">
                {wallet ? wallet : "Loading"}
              </span>
            </p>
            <div className="w-1/2 mx-auto" >
              <CustomButton text={t("_FINANCE._COPY")} onClick={() => copyWallet()} fontSize={"text-2xl"} />
            </div>
            <br />
            <p className="text-xs text-red-600 mt-4">
              {t("_FINANCE._DISCLAIMER")} 
            </p>
          </div>
          <img src={GladiaverseBottom} className="absolute bottom-0 right-0 w-30 opacity-10 lg:w-80 lg:opacity-100 hidden xl:block" />
        </div>
      </div>
      <div className="bg-gladiaverse text-white p-6 rounded-lg mt-6 h-60">
        <h2 className="text-lg font-semibold mb-4">{t("_FINANCE._TABLE1")} </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full text-white">
            <thead>
              <tr>
                <th className="py-2 px-4 text-left">{t("_FINANCE._TABLE1_A")}</th>
                <th className="py-2 px-4 text-left">{t("_FINANCE._TABLE1_B")}</th>
                <th className="py-2 px-4 text-left">{t("_FINANCE._TABLE1_C")}</th>
                <th className="py-2 px-4 text-left">{t("_FINANCE._TABLE1_D")}</th>
              </tr>
            </thead>
            <tbody>
              {listDeposit.map((deposit, index) => (
                <tr key={index}>
                  <td className="py-2 px-4">Orden #{parseInt(deposit.id) + parseInt(1000)}</td>
                  <td className="py-2 px-4">{deposit.balance} TRX</td>
                  <td className="py-2 px-4">{formatDate(deposit.created)}</td>
                  <td className="py-2 px-4"><span className={`px-2 py-1 rounded ${deposit.status === '1' ? 'bg-green-500' : 'bg-red-500'} text-white`}>{deposit.error === '0' ? 'Success' : 'In Progress'}</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
}
