import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { Link, useNavigate } from 'react-router-dom';

export default function Withdrawal() {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState();
  const [listDeposit, setListDeposit] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const { loading, updateLoading } = useContext(AppContext);

  useEffect(() => {
    getWithdrawalList();
  }, []);

  const getWithdrawalList = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/withdrawal?limit=40`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === false) {
          updateLoading(false);
        } else {
          setListDeposit(response?.data?.items);
          setListTotal(response?.data?.total)
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return formatDistanceToNow(date, { locale: es, addSuffix: true });
  }

  function formatString(str) {
    if (str.length <= 8) {
      return str; // Devuelve la cadena original si es demasiado corta para ser formateada
    }
    const firstPart = str.slice(0, 4); // Obtiene los primeros 4 dígitos
    const lastPart = str.slice(-10); // Obtiene los últimos 4 dígitos
    return `${firstPart}....${lastPart}`; // Combina las partes con puntos en el medio
  }


  return (
    <div className="mx-auto lg:p-4 h-screen">
      <div className="bg-gladiaverse text-white p-6 rounded-lg mt-6 h-auto">
        <h2 className="text-lg font-semibold mb-4">{t("_WITHDRAWAL._TITLE")} </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full text-white">
            <thead>
              <tr>
                <th className="py-2 px-4 text-left">{t("_FINANCE._TABLE1_A")}</th>
                <th className="py-2 px-4 text-left">{t("_FINANCE._TABLE1_B")}</th>
                <th className="py-2 px-4 text-left">HASH TRX</th>
                <th className="py-2 px-4 text-left">{t("_FINANCE._TABLE1_C")}</th>
                <th className="py-2 px-4 text-left">{t("_FINANCE._TABLE1_D")}</th>
              </tr>
            </thead>
            <tbody>
              {listDeposit.map((deposit, index) => (
                deposit?.trxSmart && (
                  <tr key={index} >
                    <td className="py-2 px-4">Orden #{parseInt(deposit.id) + parseInt(1000)}</td>
                    <td className="py-2 px-4">{deposit.total} TRX</td>
                    <td className="py-2 px-4 text-indigo-600"><Link to={`https://tronscan.org/#/transaction/${deposit?.trxSmart}`} target={"_blank"} >{formatString(deposit?.trxSmart || "0000000000000000")}</Link></td>
                    <td className="py-2 px-4">{formatDate(deposit.created)}</td>
                    <td className="py-2 px-4"><span className={`px-2 py-1 rounded ${deposit.status === '1' ? 'bg-green-500' : 'bg-red-500'} text-white`}>{deposit.status === '1' ? 'Success' : 'In Progress'}</span></td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
          <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4" aria-label="Table navigation">
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">{t("_DASHBOARD._SHOW")} <span className="font-semibold text-gray-900 dark:text-white mx-1">1-10</span> {t("_DASHBOARD._SHOW2")} <span className="ml-1 font-semibold text-gray-900 dark:text-white">{listTotal}</span></span>
            <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
              <li>
                <a href="#" className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{t("_DASHBOARD._PREV")}</a>
              </li>
              {/*  
                  <li>
                    <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">2</a>
                  </li>
                  <li>
                    <a href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">4</a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">5</a>
                  </li>
                    */}
              <li>
                <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{t("_DASHBOARD._NEXT")}</a>
              </li>

            </ul>
          </nav>
        </div>
      </div>
    </div >
  );
}
