import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";

const AdminPackage = () => {
  const navigate = useNavigate();
  const { loading, updateLoading } = useContext(AppContext);
  const { register, handleSubmit, setValue, watch, reset, formState: { errors }, } = useForm();
  const [userSearch, setUserSearch] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    getAllAdmin()
  }, []);

  const onSubmit = async (data) => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("email", data?.email);
    formData.append("packages_type", data?.package);
    fetch(`${process.env.REACT_APP_BACKEND}/admin-packages`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          toast.error("Error al asignar paquete, vuelve a intentarlo");
          updateLoading(false);
        } else {
          toast.success("Se agrego el paquete correctamente");
          getAllAdmin();
          reset();
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const searchEmail = async (email) => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    fetch(`${process.env.REACT_APP_BACKEND}/exists-email`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          toast.error("No existe usuario");
          updateLoading(false);
        } else {
          setUserSearch(response.response)
          if (response?.response?.name) {
            setValue('name', response?.response?.name);
          }
          toast.success("Usuario encontrado sastifactoriamente");
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const getAllAdmin = async () => {
    setUserSearch();
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/admin-packages?limit=100`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          if (response.redirect) {
            navigate("/");
          }
          updateLoading(false);
        } else {
          console.log(response?.data?.items)
          setTransactions(response?.data?.items)
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  return (
    <div className='h-screen'>
      <div className="container mx-auto h-auto flex flex-col lg:flex-row gap-4 p-4">
        <div className="w-full lg:w-1/2 bg-gladiaverse rounded-lg shadow-md text-white p-4">
          <table className="min-w-full border-collapse block md:table">
            <thead className="block md:table-header-group">
              <tr className="border border-gray-300 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto md:relative">
                <th className="block md:table-cell">Usuario</th>
                <th className="block md:table-cell">Paquete</th>
                <th className="block md:table-cell">Fecha Activación</th>
              </tr>
            </thead>
            <tbody className="block md:table-row-group">
              {transactions.map((transaction) => (
                <tr key={transaction.id} className="border border-gray-300 md:border-none block md:table-row">
                  <td className="block md:table-cell">{transaction?.name}</td>
                  <td className="block md:table-cell">Paquete N° {transaction?.packages_type}</td>
                  <td className="block md:table-cell">{transaction.created}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full lg:w-1/2 bg-gladiaverse rounded-lg shadow-md text-white p-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-white mb-2" htmlFor="email">
                Correo electrónico
              </label>
              <div className="flex flex-col lg:flex-row gap-4">
                <input
                  type="email"
                  id="email"
                  {...register('email', { required: true })}
                  className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
                />
                <button
                  type="button"
                  onClick={() => searchEmail(watch('email'))}
                  className="text-md border-2 p-1 cursor-pointer rounded-lg w-full lg:w-32"
                >
                  Buscar
                </button>
              </div>
              {errors.email && <p className="text-red-500 text-xs italic">El email es requerido</p>}
            </div>
            <div className="mb-4">
              <label className="block text-white mb-2" htmlFor="name">
                Nombre de Usuario
              </label>
              <input
                type="text"
                id="name"
                {...register('name')}
                disabled
                className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
              />
              {errors.name && <p className="text-red-500 text-xs italic">Name is required.</p>}
            </div>
            <div className="mb-4">
              <label className="block text-white mb-2" htmlFor="package">
                Paquete a asignar
              </label>
              <select
                id="package"
                {...register('package', { required: true })}
                className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
              >
                <option value="" className="bg-gray-900">Seleccione Paquete</option>
                <option value="1" className="bg-gray-900">Package 1 - 500 TRX</option>
                <option value="2" className="bg-gray-900">Package 2 - 1000 TRX</option>
                <option value="3" className="bg-gray-900">Package 3 - 2000 TRX</option>
                <option value="4" className="bg-gray-900">Package 4 - 4000 TRX</option>
                <option value="5" className="bg-gray-900">Package 5 - 8000 TRX</option>
                <option value="6" className="bg-gray-900">Package 6 - 16000 TRX</option>
                <option value="7" className="bg-gray-900">Package 7 - 32000 TRX</option>
                <option value="8" className="bg-gray-900">Package 8 - 64000 TRX</option>
              </select>
              {errors.package && <p className="text-red-500 text-xs italic">El paquete debe ser seleccionado</p>}
            </div>
            {userSearch && userSearch?.is_corporate === "0" && userSearch?.active === "1" && (
              <p class="text-xs font-medium me-2 p-2 rounded bg-red-900 text-red-300 my-2">Este usuario ya tiene un paquete comprado, si le anexas un paquete corporativo perdera todos sus beneficios</p>
            )}
            <div className="mb-4">
              <button
                type="submit"
                className="text-md border-2 p-1 cursor-pointer rounded-lg w-full"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminPackage;
