export const hin = {
  _LOGIN: {
    _TITLE: "अखाड़े में प्रवेश करें",
    _SUBTITLE: "साइन इन करने के लिए अपना ईमेल और पासवर्ड दर्ज करें",
    _EMAIL: "आपका ईमेल",
    _PASSWORD: "आपका पासवर्ड",
    _REMEMBER: "मुझे याद रखें",
    _LOGIN: "लॉगिन",
    _SIGNUP: "साइन अप",
    _FORGOTPASSWORD: "क्या आप अपना पासवर्ड भूल गए हैं?",
    _NOACCOUNT: "क्या आपका खाता नहीं है?",
    _ERROR_EMAIL: "शायद आप पंजीकृत नहीं हैं! पुनः प्रयास करें।",
    _ERROR_PASSWORD: "गलत पासवर्ड",
    _SUCCESS: "लॉगिन सफल",
  },
  _REGISTER: {
    _REGISTER: "खाता पंजीकृत करें",
    _NAME: "आपका नाम",
    _EMAIL: "आपका ईमेल",
    _PASSWORD: "आपका पासवर्ड",
    _CONFIRMPASSWORD: "पासवर्ड की पुष्टि करें",
    _REFERAL: "रेफरल कोड",
    _HAVEACCOUNT: "मेरे पास खाता है",
    _ERROR_REFERAL: "गलत रेफरल कोड",
    _ERROR_EMAIL: "यह ईमेल पहले से मौजूद है",
    _SUCCESS: "पंजीकरण सफल",
  },
  _SIDEBAR: {
    _PAGES: "पृष्ठ",
    _OPEN: "साइडबार खोलें",
    _DASHBOARD: "डैशबोर्ड",
    _NETWORK: "नेटवर्क",
    _FINANCE: "वित्त",
    _PACKAGES: "पैकेज",
    _SIGNOUT: "साइन आउट",
    _LANGUAGE: "भाषा",
    _PROFILE: "प्रोफ़ाइल",
    _LANGUAGEENGLISH: "अंग्रेज़ी",
    _LANGUAGESPANISH: "स्पेनिश",
    _LANGUAGEFRENCH: "फ्रेंच",
    _LANGUAGEGERMAN: "जर्मन",
    _LANGUAGEPORTUGUESE: "पुर्तगाली",
    _LANGUAGECHINESE: "चीनी",
    _LANGUAGEHINDI: "हिंदी",
    _LANGUAGERUSSIAN: "रूसी",
    _LANGUAGEITALIAN: "इतालवी",
  },
  _FINANCE: {
    _DEPOSIT: "जमा",
    _QR: "निम्नलिखित क्यूआर स्कैन करें या पता कॉपी करें",
    _NETWORK: "नेटवर्क",
    _INSTRUCTIONS: "ट्रॉन (टीआरएक्स) भेजने के लिए निर्देश",
    _STEP1: "सुनिश्चित करें कि चयनित नेटवर्क ट्रॉन (टीआरसी 20) है।",
    _STEP2: "निम्नलिखित वॉलेट पते पर ट्रॉन (टीआरएक्स) की मात्रा भेजें।",
    _COPY: "कॉपी",
    _DISCLAIMER:
      "अस्वीकृति: हम ट्रॉन (टीआरसी 20) नेटवर्क के बाहर हुए लेन-देन या ट्रॉन (टीआरएक्स) के अलावा क्रिप्टोकरेंसी भेजने के लिए जिम्मेदार नहीं हैं। इन त्रुटियों के कारण धन की कोई कमी भेजने वाले की एकमात्र जिम्मेदारी होगी।",
    _TABLE1: "जमा इतिहास",
    _TABLE1_A: "आदेश",
    _TABLE1_B: "राशि",
    _TABLE1_C: "तिथि",
    _TABLE1_D: "स्थिति",
    _SUCCESS: "पैकेज सफलतापूर्वक जोड़ा गया था",
    _COPYMESSAGE: "वॉलेट पता सफलतापूर्वक कॉपी किया गया",
  },
  _PACKAGES: {
    _ATTENTION:
      "ध्यान दें: आपके पास एक कॉर्पोरेट खाता है। एक पैकेज खरीदने से, आप खाता और उसके सभी लाभों को फिर से शुरू करेंगे।",
    _PACKAGES: "अपने लिए सर्वोत्तम योजना का चयन करें",
    _SUBTITLE: "आपके पास 8 विकल्प",
    _BALANCE: "शेष जमा",
    _PACKAGE: "पैकेज",
    _PACKAGE_1: "मूल्य",
    _PACKAGE_2: "बाइनरी",
    _PACKAGE_3: "दैनिक रिटर्न ऑन इन्वेस्टमेंट",
    _BUTTON1: "भरा हुआ",
    _BUTTON2: "खरीदें",
    _RECHARGE: "आपके पास पर्याप्त धन नहीं है, अपने वित्त को रीचार्ज करें",
    _PACKAGEMESSAGE: "आपको एक उच्च पैकेज खरीदना होगा",
    _SUCCESS: "पैकेज सफलतापूर्वक जोड़ा गया था",
  },
  _NETWORK: {
    _LEFT: "बाएँ",
    _RIGHT: "दाएं",
    _AVAILABLE: "उपलब्ध",
    _WITHDRAW: "कुल निकासी",
    _SEARCHLABEL: "ईमेल द्वारा खोजें",
    _SEARCH: "खोजें",
    _NETWORK: "नेटवर्क",
    _DIRECTS: "प्रत्यक्ष",
    _SUCCESS: "खोज सफल",
    _ERROR: "आपके द्विआधारी पेड़ का हिस्सा नहीं है।",
  },
  _PROFILE: {
    _TITLE: "प्रोफ़ाइल",
    _NAME: "आपका नाम",
    _EMAIL: "आपका ईमेल",
    _CURRENTPASSWORD: "वर्तमान पासवर्ड",
    _PASSWORD: "नया पासवर्ड",
    _CONFIRMPASSWORD: "नए पासवर्ड की पुष्टि करें",
    _WALLET: "निकासी पता",
    _ACTION: "संपादित करें",
    _TIPS: "टिप्स",
    _TIPS_1: "नाम स्पष्ट और 100 वर्णों से अधिक नहीं होना चाहिए।",
    _TIPS_2:
      "पासवर्ड में अंक, अक्षर और प्रतीक शामिल होने चाहिए; जितना जटिल, उतना ही अच्छा।",
    _TIPS_3:
      "ईमेल बदला नहीं जा सकता। यदि आपको कोई बदलाव करने की आवश्यकता है, तो कृपया समर्थन को लिखें।",
    _TIPS_4:
      "निकासी पता केवल उस संबंधित कोड़ के साथ संशोधित किया जा सकता है, जिसे आपने पहले सक्षम किया हो।",
    _ERROR_PASSWORD: "पिछला पासवर्ड त्रुटि",
    _SUCCESS: "प्रोफ़ाइल सफलतापूर्वक अपडेट किया गया",
    _MIN: "न्यूनतम निकासी",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "क्या आपने अपना पासवर्ड भूल गए हैं?",
    _EMAIL: "आपका ईमेल",
    _BUTTON: "ईमेल भेजें",
    _MESSAGE: "आपका पासवर्ड आपके ईमेल पर भेज दिया गया था",
    _ERROR_EMAIL: "यह ईमेल पंजीकृत नहीं है",
  },
  _DASHBOARD: {
    _TITLE: "जमा शेष",
    _TITLE2: "ग्लेडिएवर्स कुल लाभ",
    _TITLE3: "पैकेज कमाई",
    _TITLE4: "बाइनरी द्वारा लाभ",
    _WELCOME: "वापसी पर स्वागत है",
    _WELCOME2: "एर्बिट्रेशन में अपनी सफलता सुनिश्चित करें 4 सरल कदमों में",
    _STEP1: "अपना निकासी वॉलेट सेट करें",
    _STEP2: "एक पैकेज खरीदें",
    _STEP3: "अपना लाभ प्राप्त करें",
    _STEP4: "अपना नेटवर्क विस्तारित करें",
    _TABLE2: "रेफरल ट्रैकिंग",
    _LEFT: "बाएं",
    _RIGHT: "दाएं",
    _LEFT_BINARY: "बायनरी बायनरी",
    _RIGHT_BINARY: "दायां बायनरी",
    _RANDOM_BINARY: "क्रमशः बाइनरी",
    _TABLE3: "पैकेज सूची",
    _START: "शुरू",
    _FINISH: "समाप्त",
    _STATUS: "स्थिति",
    _STATUS_ACTIVE: "सक्रिय",
    _STATUS_INACTIVE: "निष्क्रिय",
    _CURRENT: "वर्तमान",
    _UPGRADE: "अपग्रेड",
    _COPYMESSAGE: "सफलतापूर्वक प्रतिलिपि",
    _NOTIFICATION3: "बाइनरी लाभ",
    _NOTIFICATION2: "एर्बिट्रेज लाभ",
    _NOTIFICATION1: "नया जमा",
    _NOTIFICATION5: "पैकेज खरीदें",
    _SHOW: "दिखा रहा है",
    _SHOW2: "का",
    _PREV: "पिछला",
    _NEXT: "आगामी",
    _TABLE4_A: "ऑपरेशन",
    _TABLE4_B: "लाभ",
    _TABLE4_C: "आरओआई",
    _TABLE4_D: "तारीख",
  },
  _VERSE: {
    _QUESTION: "सवाल",
    _ANSWER: "जवाब",
    _EMPTY: "विवरण देखने के लिए कोई सवाल चुनें।",
    _INPUT: "अपना सवाल यहाँ लिखें...",
  },
  _AUTH: {
    _TITLEDEFAULT: "वापसी वॉलेट बदलें",
    _TITLE: "चरण 1",
    _TITLE2: "चरण 2",
    _TITLE3: "चरण 3",
    _STEP1: "अपने डिवाइस पर प्रमाणीकरणकर्ता डाउनलोड और स्थापित करें",
    _STEP2: "सहेजने के लिए कोड स्कैन करें",
    _FORMEMAIL: "ईमेल सत्यापन",
    _FORMHELPEMAIL: "द्वारा भेजा गया 6 अंकों का सत्यापन कोड दर्ज करें",
    _FORMHELPAUTH:
      "प्रमाणीकरणकर्ता ऐप्लिकेशन द्वारा उत्पन्न 6 अंकों का कोड दर्ज करें",
    _FORM1: "प्रमाणीकरणकर्ता पिन",
    _FORM2: "निकासी पता",
    _FORMMIN: "निकासी पता 34 वर्णों की लंबाई होनी चाहिए",
    _FORMCODESEND: "कोड भेजें",
    _VERIFF: "सत्यापित करें",
    _ACTION: "सत्यापित करें और बचाएं",
    _ERROR: "त्रुटि, पुनः प्रयास करें।",
    _SUCCESS: "सफल परिवर्तन",
    _RETRYOTP: "कोड भेजने में त्रुटि, पुनः प्रयास करें।",
    _SENDOTP: "कोड सफलतापूर्वक भेजा गया",
    _SUCCESSOTP: "OTP सफलतापूर्वक सत्यापित",
    _SUCCESSAUTH2F: "Auth2fa सफलतापूर्वक सत्यापित",
    _ERRORCODE: "अमान्य कोड, पुनः प्रयास करें",
  },
  _COUNTDOWN: {
    _TITLE: "अगली कटौती:",
    _DAYS: "दिन",
    _HOURS: "घंटे",
    _MINUTES: "मिनट",
    _SECONDS: "सेकंड",
  },
  _WITHDRAWAL: {
    _TITLE: "निकासी लेन-देन",
  },
};
