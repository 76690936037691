import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";
import TreemapChart from '../components/TreemapChart';


export default function ArbitrageDay() {
    const [arbitrageDetail, setArbitrageDetail] = useState([]);
    const [arbitrageList, setArbitrageList] = useState([]);
    const [profit, setProfit] = useState();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [total, setTotal] = useState(0);
    const [listDeposit, setListDeposit] = useState([]);
    const { loading, updateLoading } = useContext(AppContext);
    const { t } = useTranslation();

    useEffect(() => {
        getProfit();
        getProfitDetail();
    }, []);

    const getProfit = () => {
        updateLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND}/profit`, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((response) => {
                if (!response.status) {
                    updateLoading(false);
                } else {
                    console.log(response)
                    setArbitrageDetail(response?.data)
                    setProfit(response?.profitDay)
                    updateLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    }

    useEffect(() => {
        getProfitDetail();
    }, [page, limit]);

    const getProfitDetail = () => {
        updateLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND}/profitdetail?page=${page}&limit=${limit}`, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((response) => {
                setArbitrageList(response?.data?.items)
                setTotal(response?.data?.total)
                console.log(response?.data?.items)
                updateLoading(false);
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    }


    const formatNumber = (number) => {
        if (!number) {
            return 0;
        }
        if (number >= 1000000) {
            return (number / 1000000).toFixed(2) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(2) + 'K';
        } else {
            return number.toFixed(2);
        }
    };

    return (
        <div className='flex flex-col p-4'>
            <div className="flex flex-col mx-auto pt:mt-0 gap-2 w-full ">
                <div className='flex flex-col lg:flex-row w-full gap-2'>
                    <div className='w-full lg:w-3/12 bg-zinc-900 h-32 flex flex-col justify-center items-center gap-3 p-2'>
                        <h2 className='text-gray-500'>Total Invested</h2>
                        <p className='text-slate-200 text-xl'>
                            {arbitrageDetail ? formatNumber(arbitrageDetail?.total_invested) : "Loading"} <br />
                            <small>   {arbitrageDetail ? formatNumber(arbitrageDetail?.total_invested / 0.13) + " TRX " : "Loading"} - TRX </small>
                        </p>

                    </div>
                    <div className='w-full lg:w-3/12 bg-zinc-900 h-32 flex flex-col justify-center items-center gap-3 p-2'>
                        <h2 className='text-gray-500'>Total Gain</h2>
                        <p className='text-slate-200 text-xl'>
                            {arbitrageDetail ? formatNumber(((arbitrageDetail?.total_gained * 2) + arbitrageDetail?.supply_gained_price) / 0.13) + " TRX " : "Loading"}
                        </p>
                    </div>
                    <div className='w-full lg:w-3/12 bg-zinc-900 h-32 flex flex-row justify-center items-center gap-6 p-2' >
                        <div>
                            <h2 className='text-gray-500 text-lg'>Total operations</h2>
                            <p className='text-gray-500 text-xs'>Lost: {arbitrageDetail?.total_status_0_today} OPS</p>
                            <p className='text-gray-500 text-xs'>Gain: {arbitrageDetail?.total_status_1_today} OPS</p>
                        </div>
                        <p className='text-slate-200 text-4xl'>{arbitrageDetail?.total_operations_today}</p>
                    </div>
                    <div className='w-full lg:w-3/12 bg-zinc-900 h-32 flex flex-col justify-center items-center gap-3 p-2'>
                        <h2 className='text-gray-500'>Distributed to partners</h2>
                        <p className='text-slate-200 text-xl'>{arbitrageDetail ? formatNumber(((arbitrageDetail?.total_gained * 2) + arbitrageDetail?.supply_gained_price) / 0.14) + " TRX " : "Loading"}</p>
                    </div>
                    <div className='w-full lg:w-3/12 bg-zinc-900 h-32 flex flex-row justify-center items-center gap-3 p-2'>
                        <div>
                            <h2 className='text-gray-500 text-lg'>Profit delivered</h2>
                            <p className='text-gray-500 text-xs'>Beneficiaries: {arbitrageDetail?.users_total}</p>
                        </div>
                        <p className='text-slate-200 text-4xl'>
                            {profit ? (profit.profit * 100).toFixed(2) : "Loading"}%
                        </p>
                    </div>
                </div >
            </div >
            <div className='flex flex-row gap-2 mt-2'>
                <div className="flex flex-row lg:flex-col mx-auto pt:mt-0 gap-2 w-3/4 ">
                    <div className='flex flex-row w-full gap-2 bg-zinc-900'>
                        <div className="relative overflow-x-auto w-full">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
                                <thead className="text-xs text-gray-400 uppercase bg-gray-800 ">
                                    <tr>
                                        <th className="px-6 py-3">
                                            CRYPTO
                                        </th>
                                        <th className="px-6 py-3">
                                            STATUS
                                        </th>
                                        <th className="px-6 py-3">
                                            TYPE
                                        </th>
                                        <th className="px-6 py-3">
                                            BUY EXCHANGE
                                        </th>
                                        <th className="px-6 py-3">
                                            PRICE EXCHANGE
                                        </th>
                                        <th className="px-6 py-3">
                                            SELL EXCHANGE
                                        </th>
                                        <th className="px-6 py-3">
                                            PRICE EXCHANGE
                                        </th>
                                        <th className="px-6 py-3">
                                            VOLUMEN
                                        </th>
                                        <th className="px-6 py-3">
                                            DIFFERENCE
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {arbitrageList.map((item, index) => (
                                        <tr className="border-b border-gray-700" key={index}>
                                            <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                                {item?.crypto}
                                            </th>
                                            <td className="px-6 py-4">
                                                {item?.status === "1" ? (
                                                    <span className="inline-block px-2 py-1 text-xs font-semibold text-white bg-green-500 rounded-full">
                                                        Success
                                                    </span>
                                                ) : (
                                                    <span className="inline-block px-2 py-1 text-xs font-semibold text-white bg-red-500 rounded-full">
                                                        Failed
                                                    </span>
                                                )}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.instType ? item.instType : "-"}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.buyExchange ? item.buyExchange : "-"}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.buyPrice ? item.buyPrice : "-"}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.sellExchange ? item.sellExchange : "-"}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.sellPrice ? item.sellPrice : "-"}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.volume ? item.volume : "-"} V
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.difference ? item.difference : "-"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4" aria-label="Table navigation">
                                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                                    {t("_DASHBOARD._SHOW")} <span className="font-semibold text-gray-900 dark:text-white mx-1">{(page - 1) * limit + 1}-{Math.min(page * limit, total)}</span> {t("_DASHBOARD._SHOW2")} <span className="ml-1 font-semibold text-gray-900 dark:text-white">{total}</span>
                                </span>
                                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                    <li>
                                        <button
                                            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                                            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                        >
                                            {t("_DASHBOARD._PREV")}
                                        </button>
                                    </li>
                                    {[...Array(Math.ceil(total / limit)).keys()].map((_, index) => (
                                        <li key={index}>
                                            <button
                                                onClick={() => setPage(index + 1)}
                                                className={`flex items-center justify-center px-3 h-8 leading-tight ${page === index + 1 ? 'text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <button
                                            onClick={() => setPage((prev) => Math.min(prev + 1, Math.ceil(total / limit)))}
                                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                        >
                                            {t("_DASHBOARD._NEXT")}
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className='flex flex-row w-full gap-2 bg-zinc-900 mt-4'>
                        <div className="relative overflow-x-auto w-full">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
                                <thead className="text-xs text-gray-400 uppercase bg-gray-800 ">
                                    <tr>
                                        <th className="px-6 py-3">
                                            CRYPTO
                                        </th>
                                        <th className="px-6 py-3">
                                            ASK
                                        </th>
                                        <th className="px-6 py-3">
                                            BID
                                        </th>
                                        <th className="px-6 py-3">
                                            PERCENTAGE DIFFERENCE
                                        </th>
                                        <th className="px-6 py-3">
                                            VWAP - Bridge
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {arbitrageList.map((item, index) => (
                                        <tr className="border-b border-gray-700" key={index}>
                                            <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                                {item?.crypto}
                                            </th>
                                            <td className="px-6 py-4">
                                                {item?.ask ? item.ask : "-"}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.bid ? item.bid : "-"}
                                            </td>

                                            <td className="px-6 py-4">
                                                {item?.percentageDifference ? item.percentageDifference : "-"} V
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.vwap ? item.vwap : "-"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row lg:flex-col mx-auto pt:mt-0 gap-2 w-1/4">
                    <div className='w-full bg-zinc-900 h-80'>
                        <TreemapChart data={arbitrageDetail?.distribution_by_protocol} />
                    </div>
                    <div className='w-full bg-zinc-900 h-80'>

                    </div>
                </div>
            </div>
        </div >
    );
}