export const vie = {
  _LOGIN: {
    _TITLE: "Vào đấu trường",
    _SUBTITLE: "Nhập email và mật khẩu của bạn để đăng nhập",
    _EMAIL: "Email của bạn",
    _PASSWORD: "Mật khẩu của bạn",
    _REMEMBER: "Ghi nhớ đăng nhập",
    _LOGIN: "Đăng nhập",
    _SIGNUP: "Đăng ký",
    _FORGOTPASSWORD: "Bạn quên mật khẩu?",
    _NOACCOUNT: "Không có tài khoản?",
    _ERROR_EMAIL: "Có vẻ như bạn chưa đăng ký! Hãy thử lại.",
    _ERROR_PASSWORD: "Mật khẩu không chính xác",
    _SUCCESS: "Đăng nhập thành công",
  },
  _REGISTER: {
    _REGISTER: "Đăng ký tài khoản",
    _NAME: "Tên của bạn",
    _EMAIL: "Email của bạn",
    _PASSWORD: "Mật khẩu của bạn",
    _CONFIRMPASSWORD: "Xác nhận mật khẩu",
    _REFERAL: "Mã giới thiệu",
    _HAVEACCOUNT: "Tôi có tài khoản",
    _ERROR_REFERAL: "Mã giới thiệu sai",
    _ERROR_EMAIL: "Email này đã tồn tại",
    _SUCCESS: "Đăng ký thành công",
  },
  _SIDEBAR: {
    _PAGES: "Trang",
    _OPEN: "Mở thanh bên",
    _DASHBOARD: "Bảng điều khiển",
    _NETWORK: "Mạng",
    _FINANCE: "Tài chính",
    _PACKAGES: "Gói",
    _SIGNOUT: "Đăng xuất",
    _LANGUAGE: "Ngôn ngữ",
    _PROFILE: "Hồ sơ",
    _LANGUAGEENGLISH: "Tiếng Anh",
    _LANGUAGESPANISH: "Tiếng Tây Ban Nha",
    _LANGUAGEFRENCH: "Tiếng Pháp",
    _LANGUAGEGERMAN: "Tiếng Đức",
    _LANGUAGEPORTUGUESE: "Tiếng Bồ Đào Nha",
    _LANGUAGECHINESE: "Tiếng Trung",
    _LANGUAGEHINDI: "Tiếng Hindi",
    _LANGUAGERUSSIAN: "Tiếng Nga",
    _LANGUAGEITALIAN: "Tiếng Ý",
  },
  _FINANCE: {
    _DEPOSIT: "Nạp tiền",
    _QR: "Quét mã QR sau hoặc sao chép địa chỉ",
    _NETWORK: "Mạng",
    _INSTRUCTIONS: "Hướng dẫn gửi TRON (TRX)",
    _STEP1: "Đảm bảo mạng lưới được chọn là TRON (TRC20).",
    _STEP2: "Gửi số lượng TRON (TRX) đến địa chỉ ví sau.",
    _COPY: "Sao chép",
    _DISCLAIMER:
      "Tuyên bố từ chối trách nhiệm: Chúng tôi không chịu trách nhiệm cho các giao dịch được thực hiện ngoài mạng TRON (TRC20) hoặc gửi các loại tiền điện tử khác ngoài TRON (TRX). Mọi tổn thất do những lỗi này sẽ thuộc về trách nhiệm của người gửi.",
    _TABLE1: "Lịch sử nạp tiền",
    _TABLE1_A: "Đơn hàng",
    _TABLE1_B: "Số tiền",
    _TABLE1_C: "Ngày",
    _TABLE1_D: "Trạng thái",
    _SUCCESS: "Gói đã được thêm thành công",
    _COPYMESSAGE: "Địa chỉ ví đã được sao chép thành công",
  },
  _PACKAGES: {
    _ATTENTION:
      "Chú ý: Bạn có tài khoản doanh nghiệp. Bằng cách mua gói, bạn sẽ khởi động lại tài khoản và tất cả các lợi ích của nó.",
    _PACKAGES: "Chọn kế hoạch tốt nhất cho bạn",
    _SUBTITLE: "Bạn có 8 tùy chọn",
    _BALANCE: "Số dư nạp tiền",
    _PACKAGE: "Gói ",
    _PACKAGE_1: "Giá",
    _PACKAGE_2: "Nhị phân",
    _PACKAGE_3: "Lợi nhuận hàng ngày",
    _BUTTON1: "Đã đầy",
    _BUTTON2: "Mua",
    _RECHARGE: "Bạn không đủ tiền, hãy nạp thêm tài chính",
    _PACKAGEMESSAGE: "Bạn phải mua gói cao hơn",
    _SUCCESS: "Gói đã được thêm thành công",
  },
  _NETWORK: {
    _LEFT: "Trái",
    _RIGHT: "Phải",
    _AVAILABLE: "Có sẵn",
    _WITHDRAW: "Tổng rút",
    _SEARCHLABEL: "Tìm kiếm qua email",
    _SEARCH: "Tìm kiếm",
    _NETWORK: "Mạng lưới",
    _DIRECTS: "Trực tiếp",
    _SUCCESS: "Tìm kiếm thành công",
    _ERROR: "Không phải là một phần của cây nhị phân của bạn.",
  },
  _PROFILE: {
    _TITLE: "Hồ sơ",
    _NAME: "Tên của bạn",
    _EMAIL: "Email của bạn",
    _CURRENTPASSWORD: "Mật khẩu hiện tại",
    _PASSWORD: "Mật khẩu mới",
    _CONFIRMPASSWORD: "Xác nhận mật khẩu mới",
    _WALLET: "Địa chỉ rút tiền",
    _ACTION: "Chỉnh sửa",
    _TIPS: "Mẹo",
    _TIPS_1: "Tên phải rõ ràng và không quá 100 ký tự.",
    _TIPS_2:
      "Mật khẩu phải bao gồm số, chữ cái và ký hiệu; càng phức tạp càng tốt.",
    _TIPS_3:
      "Email không thể thay đổi. Nếu bạn cần thay đổi, vui lòng viết cho bộ phận hỗ trợ.",
    _TIPS_4:
      "Địa chỉ rút tiền chỉ có thể được thay đổi với bộ xác thực 2 yếu tố, mà phải được kích hoạt trước đó.",
    _ERROR_PASSWORD: "Lỗi mật khẩu trước đó",
    _SUCCESS: "Hồ sơ đã được cập nhật thành công",
    _MIN: "Rút tiền tối thiểu",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Quên mật khẩu của bạn?",
    _EMAIL: "Email của bạn",
    _BUTTON: "Gửi email",
    _MESSAGE: "Mật khẩu của bạn đã được gửi đến email của bạn",
    _ERROR_EMAIL: "Email này chưa được đăng ký",
  },
  _DASHBOARD: {
    _TITLE: "Số dư nạp tiền",
    _TITLE2: "Tổng lợi nhuận Gladiaverse",
    _TITLE3: "Thu nhập từ gói",
    _TITLE4: "Lợi nhuận từ nhị phân",
    _WELCOME: "Chào mừng trở lại",
    _WELCOME2: "Đảm bảo thành công của bạn trong trọng tài với 4 bước đơn giản",
    _STEP1: "Thiết lập ví rút tiền của bạn",
    _STEP2: "Mua một gói",
    _STEP3: "Nhận lợi nhuận của bạn",
    _STEP4: "Mở rộng mạng lưới của bạn",
    _TABLE2: "Theo dõi giới thiệu",
    _LEFT: "Trái",
    _RIGHT: "Phải",
    _LEFT_BINARY: "Nhị phân trái",
    _RIGHT_BINARY: "Nhị phân phải",
    _RANDOM_BINARY: "Nhị phân ngẫu nhiên",
    _TABLE3: "Danh sách gói",
    _START: "Bắt đầu",
    _FINISH: "Kết thúc",
    _STATUS: "Trạng thái",
    _STATUS_ACTIVE: "Hoạt động",
    _STATUS_INACTIVE: "Không hoạt động",
    _CURRENT: "Hiện tại",
    _UPGRADE: "Nâng cấp",
    _COPYMESSAGE: "Sao chép thành công",
    _NOTIFICATION3: "Lợi nhuận nhị phân",
    _NOTIFICATION2: "Lợi nhuận trọng tài",
    _NOTIFICATION1: "Nạp tiền mới",
    _NOTIFICATION5: "Mua gói",
    _SHOW: "Hiển thị",
    _SHOW2: "của",
    _PREV: "Trước",
    _NEXT: "Tiếp theo",
    _TABLE4_A: "Hoạt động",
    _TABLE4_B: "Lợi nhuận",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Ngày",
  },
  _VERSE: {
    _QUESTION: "Câu hỏi",
    _ANSWER: "Câu trả lời",
    _EMPTY: "Chọn một câu hỏi để xem chi tiết.",
    _INPUT: "Viết câu hỏi của bạn ở đây...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Thay đổi ví rút tiền",
    _TITLE: "Bước 1",
    _TITLE2: "Bước 2",
    _TITLE3: "Bước 3",
    _STEP1: "Tải xuống và cài đặt ứng dụng xác minh trên thiết bị của bạn",
    _STEP2: "Quét mã để lưu trữ",
    _FORMEMAIL: "Xác minh Email",
    _FORMHELPEMAIL: "Nhập mã xác minh 6 chữ số đã gửi đến",
    _FORMHELPAUTH: "Nhập mã 6 chữ số được tạo bởi ứng dụng Xác minh",
    _FORM1: "PIN từ Xác minh",
    _FORM2: "Địa chỉ rút tiền",
    _FORMMIN: "Địa chỉ rút tiền phải dài 34 ký tự",
    _FORMCODESEND: "Gửi mã",
    _VERIFF: "Xác minh",
    _ACTION: "Kiểm tra và Lưu",
    _ERROR: "Lỗi, thử lại.",
    _SUCCESS: "Thay đổi thành công",
    _RETRYOTP: "Lỗi khi gửi mã, thử lại.",
    _SENDOTP: "Mã đã được gửi thành công",
    _SUCCESSOTP: "OTP xác minh thành công",
    _SUCCESSAUTH2F: "Auth2fa xác minh thành công",
    _ERRORCODE: "Mã không hợp lệ, thử lại",
  },
  _COUNTDOWN: {
    _TITLE: "Lần cắt tiếp theo:",
    _DAYS: "Ngày",
    _HOURS: "Giờ",
    _MINUTES: "Phút",
    _SECONDS: "Giây",
  },
  _WITHDRAWAL: {
    _TITLE: "Giao dịch Rút tiền",
  },
};
