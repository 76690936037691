import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import BackgroundImage from "../assets/img/background.png"
import GladiverseLogo from "../assets/svg/logo.svg"
import Support from "../assets/svg/support.svg"
import Instagram from "../assets/svg/instagram.svg"
import Telegram from "../assets/svg/telegram.svg"

export default function LayoutAdmin({ children }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [username, setUsername] = useState('');
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        const user = JSON.parse(window.localStorage.getItem("user"));
        if (user) {
            setUsername(user.name);
        }
    }, []);

    const changeLng = (language) => {
        localStorage.setItem("lng", language);
        i18n.changeLanguage(language);
    };

    const signout = () => {
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("accountAddress");
        window.location.replace("/");
    }

    const getPageName = (path) => {
        const pathParts = path.split('/');
        return pathParts.length > 1 ? pathParts[1].charAt(0).toUpperCase() + pathParts[1].slice(1) : t("_SIDEBAR._DASHBOARD");
    }

    return (
        <>
            <section className='lg:h-full bg-cover' style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <button
                    type="button"
                    className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 z-50"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <span className="sr-only">{t("_SIDEBAR._OPEN")}</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                </button>
                <aside
                    id="logo-sidebar"
                    className={`z-50 fixed top-0 left-0 lg:top-4 lg:left-4 z-40 w-64 h-screen  transition-transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'} sm:translate-x-0`}
                    aria-label="Sidebar"
                >
                    <div className="h-full px-3 py-4 overflow-y-auto bg-gladiaverse rounded-lg">
                        <div className="flex items-center ps-2.5 mb-2">
                            <Link to="/arbitrage"> <img src={GladiverseLogo} className="w-3/5 mb-4 mx-auto" alt={"Gladiaverse"} /></Link>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute top-0 left-0 text-white cursor-pointer w-10 sm:hidden" onClick={() => setMenuOpen(!menuOpen)} >
                            <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                        </svg>

                        <ul className="space-y-2 font-medium">
                            <li>
                                <Link to="/" className={`flex items-center p-2 rounded-lg text-white hover:bg-slate-950 group ${path === '/' ? 'bg-slate-900' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                                        <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                                        <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                                    </svg>
                                    <span className="ms-3">{t("_SIDEBAR._DASHBOARD")}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/finance" className={`flex items-center p-2 rounded-lg text-white hover:bg-slate-950 group ${path === '/finance' ? 'bg-slate-900' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                                        <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
                                        <path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ms-3">{t("_SIDEBAR._FINANCE")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/packages" className={`flex items-center p-2 rounded-lg text-white hover:bg-slate-950 group ${path === '/packages' ? 'bg-slate-900' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                                        <path d="M5.566 4.657A4.505 4.505 0 0 1 6.75 4.5h10.5c.41 0 .806.055 1.183.157A3 3 0 0 0 15.75 3h-7.5a3 3 0 0 0-2.684 1.657ZM2.25 12a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3v-6ZM5.25 7.5c-.41 0-.806.055-1.184.157A3 3 0 0 1 6.75 6h10.5a3 3 0 0 1 2.683 1.657A4.505 4.505 0 0 0 18.75 7.5H5.25Z" />
                                    </svg>
                                    <span className="ms-3">{t("_SIDEBAR._PACKAGES")}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/network" className={`flex items-center p-2 rounded-lg text-white hover:bg-slate-950 group ${path === '/network' ? 'bg-slate-900' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                                        <path d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                                    </svg>
                                    <span className="ms-3">{t("_SIDEBAR._NETWORK")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile" className={`flex items-center p-2 rounded-lg text-white hover:bg-slate-950 group ${path === '/versegpt' ? 'bg-slate-900' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                    <span className="ms-3">{t("_SIDEBAR._PROFILE")}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/auth" className={`flex items-center p-2 rounded-lg text-white hover:bg-slate-950 group ${path === '/versegpt' ? 'bg-slate-900' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                                        <path fill-rule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clip-rule="evenodd" />
                                    </svg>
                                    <span className="ms-3">2FA</span>
                                </Link>
                            </li>
                            {/*                             <li>
                                <Link to="/versegpt" className={`flex items-center p-2 rounded-lg text-white hover:bg-slate-950 group ${path === '/versegpt' ? 'bg-slate-900' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                                        <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                                        <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                                    </svg>
                                    <span className="flex-1 ms-3 whitespace-nowrap">VerseGPT</span>
                                    <span className="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span>
                                </Link>
                            </li>
                            */}
                            <li>
                                <div className={`flex items-center p-2 rounded-lg text-white hover:bg-slate-950 group ${path === '/versegpt' ? 'bg-slate-900' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                                        <path fill-rule="evenodd" d="M9 2.25a.75.75 0 0 1 .75.75v1.506a49.384 49.384 0 0 1 5.343.371.75.75 0 1 1-.186 1.489c-.66-.083-1.323-.151-1.99-.206a18.67 18.67 0 0 1-2.97 6.323c.318.384.65.753 1 1.107a.75.75 0 0 1-1.07 1.052A18.902 18.902 0 0 1 9 13.687a18.823 18.823 0 0 1-5.656 4.482.75.75 0 0 1-.688-1.333 17.323 17.323 0 0 0 5.396-4.353A18.72 18.72 0 0 1 5.89 8.598a.75.75 0 0 1 1.388-.568A17.21 17.21 0 0 0 9 11.224a17.168 17.168 0 0 0 2.391-5.165 48.04 48.04 0 0 0-8.298.307.75.75 0 0 1-.186-1.489 49.159 49.159 0 0 1 5.343-.371V3A.75.75 0 0 1 9 2.25ZM15.75 9a.75.75 0 0 1 .68.433l5.25 11.25a.75.75 0 1 1-1.36.634l-1.198-2.567h-6.744l-1.198 2.567a.75.75 0 0 1-1.36-.634l5.25-11.25A.75.75 0 0 1 15.75 9Zm-2.672 8.25h5.344l-2.672-5.726-2.672 5.726Z" clip-rule="evenodd" />
                                    </svg>
                                    <select
                                        id="package"
                                        className="flex-1 ms-3 whitespace-nowrap text-white bg-transparent"
                                        onChange={(e) => changeLng(e.target.value)} value={i18n.language}
                                    >
                                        <option value="" className="bg-gray-900 my-1" hidden>{t("_SIDEBAR._LANGUAGE")}</option>
                                        <option value="English" className="bg-gray-900">English - 🇬🇧</option>
                                        <option value="Spanish" className="bg-gray-900">Español - 🇪🇸</option>
                                        <option value="French" className="bg-gray-900">Français - 🇫🇷</option>
                                        <option value="German" className="bg-gray-900">Deutsch - 🇩🇪</option>
                                        <option value="Portuguese" className="bg-gray-900">Português - 🇵🇹</option>
                                        <option value="Chinese" className="bg-gray-900">中文 - 🇨🇳</option>
                                        <option value="Hindi" className="bg-gray-900">हिंदी - 🇮🇳</option>
                                        <option value="Russian" className="bg-gray-900">Русский - 🇷🇺</option>
                                        <option value="Italian" className="bg-gray-900">Italiano - 🇮🇹</option>
                                        <option value="Vietnamese" className="bg-gray-900">Tiếng Việt - 🇻🇳</option>
                                        <option value="Tailanda" className="bg-gray-900">ประเทศไทย - 🇹🇭</option>
                                        <option value="Filipino" className="bg-gray-900">Pilipinas - 🇵🇭</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div onClick={() => signout()} className="lg:hidden flex items-center p-2 rounded-lg text-red-100 bg-red-950 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-red-800 bg-red-950 transition duration-75 group-hover:bg-red-800 group-hover:text-white group-hover:font-bold">
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                    <span className="ms-3">{t("_SIDEBAR._SIGNOUT")}</span>
                                </div>
                            </li>

                        </ul>
                        <div className="bg-slate-950 p-1 lg:mt-10 mt-3 py-4 rounded-lg">
                            <div className="flex justify-around flex-row w-full  my-2 ">
                                <Link to="https://t.me/+JuhgaGj5PrJhMGUx" target={"_blank"}><img src={Telegram} alt="Telegram" className="opacity-40 hover:opacity-100 cursor-pointer w-8" /></Link>
                                <Link to="https://www.instagram.com/gladiaverse" target={"_blank"}><img src={Instagram} alt="Instagran" className="opacity-40 hover:opacity-100 cursor-pointer w-8" /></Link>
                            </div>
                        </div>
                    </div>
                </aside>
                <div className="px-4 sm:ml-64">
                    <nav className="p-4 hidden lg:flex">
                        <div className="container mx-auto flex justify-between items-center ">
                            <div className="flex items-center space-x-2 text-white ">
                                <Link to="#" className="hover:underline">{t("_SIDEBAR._PAGES")}</Link>
                                <span>/</span>
                                <Link to="#" className="hover:underline">{getPageName(path)}</Link>
                            </div>
                            <div className="flex items-center space-x-4 z-40">
                                {/*  <div className="relative">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-gray-600 cursor-pointer ">
                                        <path fillRule="evenodd" d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z" clipRule="evenodd" />
                                    </svg>
                                    <span className="absolute -top-3 left-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full" >3</span>
                                </div>
                                */}
                                <div className="relative">
                                    <img src={GladiverseLogo} className="w-12 h-12 bg-gladiaverse rounded-full cursor-pointer opacity-80 hover:opacity-100" id="avatar" onClick={() => setProfileOpen(!profileOpen)} alt="Gladiaverse User" />
                                    <div id="menu" className={` ${!profileOpen ? "hidden" : ""} absolute right-0 mt-2 w-48 bg-gladiaverse rounded-md shadow-lg py-1 cursor-pointer `}>
                                        <div className="block px-4 py-2 text-sm text-white">@{username}</div>
                                        <Link to="/profile" className="block px-4 py-2 text-sm text-white hover:bg-slate-950">{t("_SIDEBAR._PROFILE")}</Link>
                                        <div className="block px-4 py-2 text-sm text-white hover:bg-slate-950 cursor-pointer" onClick={() => signout()}>{t("_SIDEBAR._SIGNOUT")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    {children}
                </div>
            </section >
        </>
    );
}
