export const ita = {
  _LOGIN: {
    _TITLE: "Entra nell'arena",
    _SUBTITLE: "Inserisci la tua email e password per accedere",
    _EMAIL: "La tua email",
    _PASSWORD: "La tua password",
    _REMEMBER: "Ricordami",
    _LOGIN: "Accedi",
    _SIGNUP: "Registrati",
    _FORGOTPASSWORD: "Hai dimenticato la password?",
    _NOACCOUNT: "Non hai un account?",
    _ERROR_EMAIL: "Apparentemente non sei registrato! Riprova.",
    _ERROR_PASSWORD: "Password incorretta",
    _SUCCESS: "Accesso riuscito",
  },
  _REGISTER: {
    _REGISTER: "Registra Account",
    _NAME: "Il tuo nome",
    _EMAIL: "La tua email",
    _PASSWORD: "La tua password",
    _CONFIRMPASSWORD: "Conferma Password",
    _REFERAL: "Codice di riferimento",
    _HAVEACCOUNT: "Ho un account",
    _ERROR_REFERAL: "Codice di riferimento errato",
    _ERROR_EMAIL: "Questa email esiste già",
    _SUCCESS: "Registrazione riuscita",
  },
  _SIDEBAR: {
    _PAGES: "Pagine",
    _OPEN: "Apri barra laterale",
    _DASHBOARD: "Dashboard",
    _NETWORK: "Rete",
    _FINANCE: "Finanza",
    _PACKAGES: "Pacchetti",
    _SIGNOUT: "Esci",
    _LANGUAGE: "Lingua",
    _PROFILE: "Profilo",
    _LANGUAGEENGLISH: "Inglese",
    _LANGUAGESPANISH: "Spagnolo",
    _LANGUAGEFRENCH: "Francese",
    _LANGUAGEGERMAN: "Tedesco",
    _LANGUAGEPORTUGUESE: "Portoghese",
    _LANGUAGECHINESE: "Cinese",
    _LANGUAGEHINDI: "Hindi",
    _LANGUAGERUSSIAN: "Russo",
    _LANGUAGEITALIAN: "Italiano",
  },
  _FINANCE: {
    _DEPOSIT: "Deposito",
    _QR: "Scansiona il seguente QR o copia l'indirizzo",
    _NETWORK: "Rete",
    _INSTRUCTIONS: "Istruzioni per l'invio di TRON (TRX)",
    _STEP1: "Assicurati che la rete selezionata sia TRON (TRC20).",
    _STEP2:
      "Invia l'importo di TRON (TRX) al seguente indirizzo del portafoglio.",
    _COPY: "Copia",
    _DISCLAIMER:
      "Disclaimer: non siamo responsabili delle transazioni effettuate al di fuori della rete TRON (TRC20) o per l'invio di criptovalute diverse da TRON (TRX). Eventuali perdite di fondi dovute a questi errori saranno di esclusiva responsabilità del mittente.",
    _TABLE1: "Cronologia dei depositi",
    _TABLE1_A: "Ordine",
    _TABLE1_B: "Importo",
    _TABLE1_C: "Data",
    _TABLE1_D: "Stato",
    _SUCCESS: "Il pacchetto è stato aggiunto con successo",
    _COPYMESSAGE: "Indirizzo del portafoglio copiato con successo",
  },
  _PACKAGES: {
    _ATTENTION:
      "Attenzione: hai un account aziendale. Acquistando un pacchetto, riavvierai l'account e tutti i suoi vantaggi.",
    _PACKAGES: "Seleziona il miglior piano per te",
    _SUBTITLE: "Hai 8 opzioni",
    _BALANCE: "Saldo deposito",
    _PACKAGE: "Pacchetto ",
    _PACKAGE_1: "Prezzo",
    _PACKAGE_2: "Binario",
    _PACKAGE_3: "ROI giornaliero",
    _BUTTON1: "Compilato",
    _BUTTON2: "Acquista",
    _RECHARGE: "Non hai abbastanza denaro, ricarica le tue finanze",
    _PACKAGEMESSAGE: "Devi acquistare un pacchetto più grande",
    _SUCCESS: "Il pacchetto è stato aggiunto con successo",
  },
  _NETWORK: {
    _LEFT: "Sinistra",
    _RIGHT: "Destra",
    _AVAILABLE: "Disponibile",
    _WITHDRAW: "Totale storico",
    _SEARCHLABEL: "Ricerca per email",
    _SEARCH: "Cerca",
    _NETWORK: "Rete",
    _DIRECTS: "Diretti",
    _SUCCESS: "Ricerca riuscita",
    _ERROR: "Non fa parte del tuo albero binario.",
  },
  _PROFILE: {
    _TITLE: "Profilo",
    _NAME: "Il tuo nome",
    _EMAIL: "La tua email",
    _CURRENTPASSWORD: "Password attuale",
    _PASSWORD: "Nuova password",
    _CONFIRMPASSWORD: "Conferma nuova password",
    _WALLET: "Indirizzo di prelievo",
    _ACTION: "Modifica",
    _TIPS: "Suggerimenti",
    _TIPS_1: "Il nome deve essere leggibile e non superare i 100 caratteri.",
    _TIPS_2:
      "La password deve includere numeri, lettere e simboli; più è complessa, meglio è.",
    _TIPS_3:
      "L'email non può essere modificata. Se è necessario apportare modifiche, si prega di scrivere a supporto.",
    _TIPS_4:
      "L'indirizzo di prelievo può essere modificato solo con un autenticatore a 2 fattori, che deve essere abilitato preventivamente.",
    _ERROR_PASSWORD: "Errore di password precedente",
    _SUCCESS: "Profilo aggiornato con successo",
    _MIN: "Prelievo minimo",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Hai dimenticato la password?",
    _EMAIL: "La tua email",
    _BUTTON: "Invia email",
    _MESSAGE: "La tua password è stata inviata alla tua email",
    _ERROR_EMAIL: "Questa email non è registrata",
  },
  _DASHBOARD: {
    _TITLE: "Saldo deposito",
    _TITLE2: "Profitto totale Gladiaverse",
    _TITLE3: "Guadagni del pacchetto",
    _TITLE4: "Profitto binario",
    _WELCOME: "Bentornato",
    _WELCOME2:
      "Assicura il tuo successo nell'arbitraggio in 4 semplici passaggi",
    _STEP1: "Configura il tuo portafoglio di prelievo",
    _STEP2: "Acquista un pacchetto",
    _STEP3: "Ricevi i tuoi profitti",
    _STEP4: "Espandi la tua rete",
    _TABLE2: "Tracciamento referral",
    _LEFT: "Sinistra",
    _RIGHT: "Destra",
    _LEFT_BINARY: "Binario sinistro",
    _RIGHT_BINARY: "Binario destro",
    _RANDOM_BINARY: "Binario casuale",
    _TABLE3: "Elenco pacchetti",
    _START: "Inizio",
    _FINISH: "Fine",
    _STATUS: "Stato",
    _STATUS_ACTIVE: "Attivo",
    _STATUS_INACTIVE: "Inattivo",
    _CURRENT: "Corrente",
    _UPGRADE: "Aggiornamento",
    _COPYMESSAGE: "Copia riuscita",
    _NOTIFICATION3: "Profitto binario",
    _NOTIFICATION2: "Profitto arbitrato",
    _NOTIFICATION1: "Nuovo deposito",
    _NOTIFICATION5: "Acquisto pacchetto",
    _SHOW: "Visualizzazione",
    _SHOW2: "di",
    _PREV: "Precedente",
    _NEXT: "Successivo",
    _TABLE4_A: "Operazione",
    _TABLE4_B: "Profitto",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Data",
  },
  _VERSE: {
    _QUESTION: "Domanda",
    _ANSWER: "Risposta",
    _EMPTY: "Seleziona una domanda per vedere i dettagli.",
    _INPUT: "Scrivi qui la tua domanda...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Cambia portafoglio di prelievo",
    _TITLE: "Passo 1",
    _TITLE2: "Passo 2",
    _TITLE3: "Passo 3",
    _STEP1: "Scarica e installa l'autenticatore sul tuo dispositivo",
    _STEP2: "Scansiona il codice per salvare",
    _FORMEMAIL: "Verifica dell'email",
    _FORMHELPEMAIL: "Inserisci il codice di verifica a 6 cifre inviato a",
    _FORMHELPAUTH:
      "Inserisci il codice a 6 cifre generato dall'app Autenticatore",
    _FORM1: "PIN dell'autenticatore",
    _FORM2: "Indirizzo di prelievo",
    _FORMMIN: "L'indirizzo di prelievo deve essere lungo 34 caratteri",
    _FORMCODESEND: "Invia codice",
    _VERIFF: "Verifica",
    _ACTION: "Verifica e Salva",
    _ERROR: "Errore, riprova.",
    _SUCCESS: "Cambio avvenuto con successo",
    _RETRYOTP: "Errore nell'invio del codice, riprova.",
    _SENDOTP: "Codice inviato con successo",
    _SUCCESSOTP: "OTP verificato con successo",
    _SUCCESSAUTH2F: "Auth2fa verificato con successo",
    _ERRORCODE: "Codice non valido, riprova",
  },
  _COUNTDOWN: {
    _TITLE: "Prossimo taglio:",
    _DAYS: "Giorni",
    _HOURS: "Ore",
    _MINUTES: "Minuti",
    _SECONDS: "Secondi",
  },
  _WITHDRAWAL: {
    _TITLE: "Transazioni di Prelievo",
  },
};
