export const por = {
  _LOGIN: {
    _TITLE: "Entre na arena",
    _SUBTITLE: "Insira seu e-mail e senha para entrar",
    _EMAIL: "Seu e-mail",
    _PASSWORD: "Sua senha",
    _REMEMBER: "Lembrar de mim",
    _LOGIN: "Entrar",
    _SIGNUP: "Inscrever-se",
    _FORGOTPASSWORD: "Esqueceu sua senha?",
    _NOACCOUNT: "Não tem uma conta?",
    _ERROR_EMAIL: "Aparentemente você não está registrado! Tente novamente.",
    _ERROR_PASSWORD: "Senha incorreta",
    _SUCCESS: "Login bem-sucedido",
  },
  _REGISTER: {
    _REGISTER: "Registrar Conta",
    _NAME: "Seu Nome",
    _EMAIL: "Seu e-mail",
    _PASSWORD: "Sua senha",
    _CONFIRMPASSWORD: "Confirmar Senha",
    _REFERAL: "Código de Referência",
    _HAVEACCOUNT: "Já tenho uma conta",
    _ERROR_REFERAL: "Código de referência incorreto",
    _ERROR_EMAIL: "Este e-mail já existe",
    _SUCCESS: "Registro bem-sucedido",
  },
  _SIDEBAR: {
    _PAGES: "Páginas",
    _OPEN: "Abrir barra lateral",
    _DASHBOARD: "Painel",
    _NETWORK: "Rede",
    _FINANCE: "Finanças",
    _PACKAGES: "Pacotes",
    _SIGNOUT: "Sair",
    _LANGUAGE: "Idioma",
    _PROFILE: "Perfil",
    _LANGUAGEENGLISH: "Inglês",
    _LANGUAGESPANISH: "Espanhol",
    _LANGUAGEFRENCH: "Francês",
    _LANGUAGEGERMAN: "Alemão",
    _LANGUAGEPORTUGUESE: "Português",
    _LANGUAGECHINESE: "Chinês",
    _LANGUAGEHINDI: "Hindi",
    _LANGUAGERUSSIAN: "Russo",
    _LANGUAGEITALIAN: "Italiano",
  },
  _FINANCE: {
    _DEPOSIT: "Depósito",
    _QR: "Escaneie o QR a seguir ou copie o endereço",
    _NETWORK: "Rede",
    _INSTRUCTIONS: "Instruções para enviar TRON (TRX)",
    _STEP1: "Certifique-se de que a rede selecionada é TRON (TRC20).",
    _STEP2:
      "Envie a quantia de TRON (TRX) para o seguinte endereço de carteira.",
    _COPY: "Copiar",
    _DISCLAIMER:
      "Isenção de responsabilidade: Não somos responsáveis por transações feitas fora da rede TRON (TRC20) ou pelo envio de criptomoedas que não sejam TRON (TRX). Qualquer perda de fundos devido a esses erros será de total responsabilidade do remetente.",
    _TABLE1: "Histórico de Depósitos",
    _TABLE1_A: "Pedido",
    _TABLE1_B: "Quantidade",
    _TABLE1_C: "Data",
    _TABLE1_D: "Status",
    _SUCCESS: "O pacote foi adicionado com sucesso",
    _COPYMESSAGE: "Endereço da carteira copiado com sucesso",
  },
  _PACKAGES: {
    _ATTENTION:
      "Atenção: Você tem uma conta corporativa. Ao comprar um pacote, você reiniciará a conta e todos os seus benefícios.",
    _PACKAGES: "Selecione o melhor plano para você",
    _SUBTITLE: "Você tem 8 opções",
    _BALANCE: "Saldo de Depósito",
    _PACKAGE: "Pacote",
    _PACKAGE_1: "Preço",
    _PACKAGE_2: "Binário",
    _PACKAGE_3: "ROI Diário",
    _BUTTON1: "Preenchido",
    _BUTTON2: "Comprar",
    _RECHARGE: "Você não tem dinheiro suficiente, recarregue suas finanças",
    _PACKAGEMESSAGE: "Você deve comprar um pacote superior",
    _SUCCESS: "O pacote foi adicionado com sucesso",
  },
  _NETWORK: {
    _LEFT: "Esquerda",
    _RIGHT: "Direita",
    _AVAILABLE: "Disponível",
    _WITHDRAW: "Total histórico",
    _SEARCHLABEL: "Busca por email",
    _SEARCH: "Buscar",
    _NETWORK: "Rede",
    _DIRECTS: "Diretos",
    _SUCCESS: "Busca bem-sucedida",
    _ERROR: "Não faz parte da sua árvore binária.",
  },
  _PROFILE: {
    _TITLE: "Perfil",
    _NAME: "Seu Nome",
    _EMAIL: "Seu e-mail",
    _CURRENTPASSWORD: "Senha atual",
    _PASSWORD: "Nova senha",
    _CONFIRMPASSWORD: "Confirmar nova senha",
    _WALLET: "Endereço de Saque",
    _ACTION: "Editar",
    _TIPS: "Dicas",
    _TIPS_1: "O nome deve ser legível e não deve exceder 100 caracteres.",
    _TIPS_2:
      "A senha deve incluir números, letras e símbolos; quanto mais complexa, melhor.",
    _TIPS_3:
      "O e-mail não pode ser alterado. Se precisar fazer uma alteração, escreva para o suporte.",
    _TIPS_4:
      "O endereço de saque só pode ser modificado com um autenticador de 2 fatores, que deve ser ativado previamente.",
    _ERROR_PASSWORD: "Erro na senha anterior",
    _SUCCESS: "Perfil atualizado com sucesso",
    _MIN: "Retirada mínima",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Esqueceu sua senha?",
    _EMAIL: "Seu e-mail",
    _BUTTON: "Enviar e-mail",
    _MESSAGE: "Sua senha foi enviada para seu e-mail",
    _ERROR_EMAIL: "Este e-mail não está registrado",
  },
  _DASHBOARD: {
    _TITLE: "Saldo de Depósito",
    _TITLE2: "Lucro total do Gladiaverse",
    _TITLE3: "Ganhos do Pacote",
    _TITLE4: "Lucro Binário",
    _WELCOME: "Bem-vindo de volta",
    _WELCOME2: "Garanta seu sucesso na arbitragem em 4 passos simples",
    _STEP1: "Configure sua carteira de saque",
    _STEP2: "Compre um pacote",
    _STEP3: "Receba seus lucros",
    _STEP4: "Expanda sua rede",
    _TABLE2: "Acompanhamento de Referências",
    _LEFT: "Esquerda",
    _RIGHT: "Direita",
    _LEFT_BINARY: "Binário esquerdo",
    _RIGHT_BINARY: "Binário direito",
    _RANDOM_BINARY: "Binário aleatório",
    _TABLE3: "Lista de Pacotes",
    _START: "Início",
    _FINISH: "Fim",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Ativo",
    _STATUS_INACTIVE: "Inativo",
    _CURRENT: "Atual",
    _UPGRADE: "Atualizar",
    _COPYMESSAGE: "Cópia bem-sucedida",
    _NOTIFICATION3: "Lucro Binário",
    _NOTIFICATION2: "Lucro da Arbitragem",
    _NOTIFICATION1: "Novo Depósito",
    _NOTIFICATION5: "Compra de Pacote",
    _SHOW: "Mostrando",
    _SHOW2: "de",
    _PREV: "Anterior",
    _NEXT: "Próximo",
    _TABLE4_A: "Operação",
    _TABLE4_B: "Lucro",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Data",
  },
  _VERSE: {
    _QUESTION: "Pergunta",
    _ANSWER: "Resposta",
    _EMPTY: "Selecione uma pergunta para ver os detalhes.",
    _INPUT: "Escreva sua pergunta aqui...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Alterar carteira de saque",
    _TITLE: "Passo 1",
    _TITLE2: "Passo 2",
    _TITLE3: "Passo 3",
    _STEP1: "Baixe e instale o autenticador no seu dispositivo",
    _STEP2: "Escanear o código para salvar",
    _FORMEMAIL: "Verificação por e-mail",
    _FORMHELPEMAIL: "Digite o código de verificação de 6 dígitos enviado para",
    _FORMHELPAUTH:
      "Digite o código de 6 dígitos gerado pelo aplicativo Autenticador",
    _FORM1: "PIN do autenticador",
    _FORM2: "Endereço de saque",
    _FORMMIN: "O endereço de saque deve ter 34 caracteres de comprimento",
    _FORMCODESEND: "Enviar código",
    _VERIFF: "Verificar",
    _ACTION: "Verificar e Salvar",
    _ERROR: "Erro, tente novamente.",
    _SUCCESS: "Alteração bem-sucedida",
    _RETRYOTP: "Erro ao enviar o código, tente novamente.",
    _SENDOTP: "Código enviado com sucesso",
    _SUCCESSOTP: "OTP verificado com sucesso",
    _SUCCESSAUTH2F: "Auth2fa verificado com sucesso",
    _ERRORCODE: "Código inválido, tente novamente",
  },
  _COUNTDOWN: {
    _TITLE: "Próximo corte:",
    _DAYS: "Dias",
    _HOURS: "Horas",
    _MINUTES: "Minutos",
    _SECONDS: "Segundos",
  },
  _WITHDRAWAL: {
    _TITLE: "Transações de Saque",
  },
};
