import { useState, useEffect } from "react";
import initialState from "../initialState";
const { REACT_APP_PROJECT_ID } = process.env;

const useInitialState = (key, initialValue) => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(initialState.loading);

  const updateLoading = (newLoading) => {
    setLoading(newLoading);
  };

  const setBalance = (payload) => {
    setState((prevState) => {
      return {
        ...prevState,
        balance: payload,
      };
    });
  };

  return {
    setBalance,
    updateLoading,
    loading,
    state,
  };
};

export default useInitialState;
