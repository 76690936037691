import { useState, useContext, ReactNode } from "react";
import toast, { Toaster } from "react-hot-toast";
import AppContext from "../context/AppContext";
import Loading from "../components/Loading";

export default function Layout({ children }) {
    const { loading } = useContext(AppContext);
    return (
        <>
            {loading && <Loading />}

            {children}
            <Toaster position="top-right"
                toastOptions={{
                    className: '',
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }} />
        </>
    );
}
