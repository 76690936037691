export const chi = {
  _LOGIN: {
    _TITLE: "进入竞技场",
    _SUBTITLE: "输入您的电子邮件和密码进行登录",
    _EMAIL: "您的电子邮件",
    _PASSWORD: "您的密码",
    _REMEMBER: "记住我",
    _LOGIN: "登录",
    _SIGNUP: "注册",
    _FORGOTPASSWORD: "忘记密码了吗？",
    _NOACCOUNT: "还没有账号？",
    _ERROR_EMAIL: "您似乎尚未注册！请重试。",
    _ERROR_PASSWORD: "密码错误",
    _SUCCESS: "登录成功",
  },
  _REGISTER: {
    _REGISTER: "注册账号",
    _NAME: "您的姓名",
    _EMAIL: "您的电子邮件",
    _PASSWORD: "您的密码",
    _CONFIRMPASSWORD: "确认密码",
    _REFERAL: "推荐码",
    _HAVEACCOUNT: "我已经有一个账号",
    _ERROR_REFERAL: "错误的推荐码",
    _ERROR_EMAIL: "此电子邮件已存在",
    _SUCCESS: "注册成功",
  },
  _SIDEBAR: {
    _PAGES: "页面",
    _OPEN: "打开侧边栏",
    _DASHBOARD: "仪表盘",
    _NETWORK: "网络",
    _FINANCE: "财务",
    _PACKAGES: "套餐",
    _SIGNOUT: "登出",
    _LANGUAGE: "语言",
    _PROFILE: "个人资料",
    _LANGUAGEENGLISH: "英语",
    _LANGUAGESPANISH: "西班牙语",
    _LANGUAGEFRENCH: "法语",
    _LANGUAGEGERMAN: "德语",
    _LANGUAGEPORTUGUESE: "葡萄牙语",
    _LANGUAGECHINESE: "中文",
    _LANGUAGEHINDI: "印地语",
    _LANGUAGERUSSIAN: "俄语",
    _LANGUAGEITALIAN: "意大利语",
  },
  _FINANCE: {
    _DEPOSIT: "存款",
    _QR: "扫描以下二维码或复制地址",
    _NETWORK: "网络",
    _INSTRUCTIONS: "TRON（TRX）发送说明",
    _STEP1: "确保所选网络为TRON（TRC20）。",
    _STEP2: "将TRON（TRX）金额发送到以下钱包地址。",
    _COPY: "复制",
    _DISCLAIMER:
      "免责声明：我们不对在TRON（TRC20）网络之外进行的交易或发送除TRON（TRX）以外的加密货币负责。由于这些错误造成的任何资金损失将完全由发送方承担。",
    _TABLE1: "存款历史",
    _TABLE1_A: "订单",
    _TABLE1_B: "金额",
    _TABLE1_C: "日期",
    _TABLE1_D: "状态",
    _SUCCESS: "套餐添加成功",
    _COPYMESSAGE: "钱包地址复制成功",
  },
  _PACKAGES: {
    _ATTENTION: "注意：您有一个公司账户。购买套餐将重新启动账户及其所有权益。",
    _PACKAGES: "为您选择最佳计划",
    _SUBTITLE: "您有8个选项",
    _BALANCE: "余额存款",
    _PACKAGE: "套餐 ",
    _PACKAGE_1: "价格",
    _PACKAGE_2: "二进制",
    _PACKAGE_3: "每日投资回报率",
    _BUTTON1: "已填写",
    _BUTTON2: "购买",
    _RECHARGE: "您的资金不足，请充值您的财务",
    _PACKAGEMESSAGE: "您必须购买更高的套餐",
    _SUCCESS: "套餐添加成功",
  },
  _NETWORK: {
    _LEFT: "左",
    _RIGHT: "右",
    _AVAILABLE: "可用的",
    _WITHDRAW: "历史总数",
    _SEARCHLABEL: "通过电子邮件搜索",
    _SEARCH: "搜索",
    _NETWORK: "网络",
    _DIRECTS: "直接",
    _SUCCESS: "搜索成功",
    _ERROR: "不属于你的二叉树。",
  },
  _PROFILE: {
    _TITLE: "个人资料",
    _NAME: "您的姓名",
    _EMAIL: "您的电子邮件",
    _CURRENTPASSWORD: "当前密码",
    _PASSWORD: "新密码",
    _CONFIRMPASSWORD: "确认新密码",
    _WALLET: "提款地址",
    _ACTION: "编辑",
    _TIPS: "提示",
    _TIPS_1: "姓名必须清晰可读，且不超过100个字符。",
    _TIPS_2: "密码必须包含数字、字母和符号；越复杂越好。",
    _TIPS_3: "电子邮件地址无法更改。如果您需要更改，请写信给支持团队。",
    _TIPS_4: "提款地址只能使用先前启用的两步验证器修改。",
    _ERROR_PASSWORD: "先前密码错误",
    _SUCCESS: "个人资料更新成功",
    _MIN: "最低提款",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "忘记密码?",
    _EMAIL: "您的电子邮件",
    _BUTTON: "发送邮件",
    _MESSAGE: "您的密码已发送到您的电子邮件",
    _ERROR_EMAIL: "此电子邮件未注册",
  },
  _DASHBOARD: {
    _TITLE: "存款余额",
    _TITLE2: "Gladiaverse 总利润",
    _TITLE3: "套餐收益",
    _TITLE4: "二进制利润",
    _WELCOME: "欢迎回来",
    _WELCOME2: "通过 4 个简单步骤确保您在仲裁中取得成功",
    _STEP1: "设置您的提款钱包",
    _STEP2: "购买套餐",
    _STEP3: "收取您的利润",
    _STEP4: "扩展您的网络",
    _TABLE2: "推荐追踪",
    _LEFT: "左侧",
    _RIGHT: "右侧",
    _LEFT_BINARY: "左侧二进制",
    _RIGHT_BINARY: "右侧二进制",
    _RANDOM_BINARY: "随机二进制",
    _TABLE3: "套餐列表",
    _START: "开始",
    _FINISH: "完成",
    _STATUS: "状态",
    _STATUS_ACTIVE: "活跃",
    _STATUS_INACTIVE: "不活跃",
    _CURRENT: "当前",
    _UPGRADE: "升级",
    _COPYMESSAGE: "复制成功",
    _NOTIFICATION3: "二进制利润",
    _NOTIFICATION2: "套利利润",
    _NOTIFICATION1: "新存款",
    _NOTIFICATION5: "购买套餐",
    _SHOW: "显示",
    _SHOW2: "共",
    _PREV: "上一页",
    _NEXT: "下一页",
    _TABLE4_A: "操作",
    _TABLE4_B: "利润",
    _TABLE4_C: "投资回报率",
    _TABLE4_D: "日期",
  },
  _VERSE: {
    _QUESTION: "问题",
    _ANSWER: "答案",
    _EMPTY: "选择问题查看详情。",
    _INPUT: "在此处编写您的问题...",
  },
  _AUTH: {
    _TITLEDEFAULT: "更改提款钱包",
    _TITLE: "第1步",
    _TITLE2: "第2步",
    _TITLE3: "第3步",
    _STEP1: "下载并安装身份验证器到您的设备",
    _STEP2: "扫描代码以保存",
    _FORMEMAIL: "电子邮件验证",
    _FORMHELPEMAIL: "输入发送到的6位数验证码",
    _FORMHELPAUTH: "输入身份验证器应用程序生成的6位数代码",
    _FORM1: "身份验证器PIN码",
    _FORM2: "提款地址",
    _FORMMIN: "提款地址必须为34个字符长",
    _FORMCODESEND: "发送代码",
    _VERIFF: "验证",
    _ACTION: "检查并保存",
    _ERROR: "错误，请重试。",
    _SUCCESS: "更改成功",
    _RETRYOTP: "发送验证码错误，请重试。",
    _SENDOTP: "验证码发送成功",
    _SUCCESSOTP: "OTP验证成功",
    _SUCCESSAUTH2F: "Auth2fa验证成功",
    _ERRORCODE: "无效代码，请重试",
  },
  _COUNTDOWN: {
    _TITLE: "下次切割：",
    _DAYS: "天",
    _HOURS: "小时",
    _MINUTES: "分钟",
    _SECONDS: "秒",
  },
  _WITHDRAWAL: {
    _TITLE: "提款交易",
  },
};
