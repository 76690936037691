export const tha = {
  _LOGIN: {
    _TITLE: "เข้าสู่เวที",
    _SUBTITLE: "ป้อนอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ",
    _EMAIL: "อีเมลของคุณ",
    _PASSWORD: "รหัสผ่านของคุณ",
    _REMEMBER: "จดจำฉัน",
    _LOGIN: "เข้าสู่ระบบ",
    _SIGNUP: "สมัครสมาชิก",
    _FORGOTPASSWORD: "ลืมรหัสผ่านหรือไม่?",
    _NOACCOUNT: "ยังไม่มีบัญชี?",
    _ERROR_EMAIL: "ดูเหมือนว่าคุณยังไม่ได้ลงทะเบียน! ลองอีกครั้ง",
    _ERROR_PASSWORD: "รหัสผ่านไม่ถูกต้อง",
    _SUCCESS: "เข้าสู่ระบบสำเร็จ",
  },
  _REGISTER: {
    _REGISTER: "ลงทะเบียนบัญชี",
    _NAME: "ชื่อของคุณ",
    _EMAIL: "อีเมลของคุณ",
    _PASSWORD: "รหัสผ่านของคุณ",
    _CONFIRMPASSWORD: "ยืนยันรหัสผ่าน",
    _REFERAL: "รหัสการอ้างอิง",
    _HAVEACCOUNT: "ฉันมีบัญชีแล้ว",
    _ERROR_REFERAL: "รหัสการอ้างอิงไม่ถูกต้อง",
    _ERROR_EMAIL: "อีเมลนี้มีอยู่แล้ว",
    _SUCCESS: "ลงทะเบียนสำเร็จ",
  },
  _SIDEBAR: {
    _PAGES: "หน้า",
    _OPEN: "เปิดแถบด้านข้าง",
    _DASHBOARD: "แผงควบคุม",
    _NETWORK: "เครือข่าย",
    _FINANCE: "การเงิน",
    _PACKAGES: "แพ็คเกจ",
    _SIGNOUT: "ออกจากระบบ",
    _LANGUAGE: "ภาษา",
    _PROFILE: "โปรไฟล์",
    _LANGUAGEENGLISH: "ภาษาอังกฤษ",
    _LANGUAGESPANISH: "สเปน",
    _LANGUAGEFRENCH: "ฝรั่งเศส",
    _LANGUAGEGERMAN: "เยอรมัน",
    _LANGUAGEPORTUGUESE: "โปรตุเกส",
    _LANGUAGECHINESE: "จีน",
    _LANGUAGEHINDI: "ฮินดี",
    _LANGUAGERUSSIAN: "รัสเซีย",
    _LANGUAGEITALIAN: "อิตาลี",
  },
  _FINANCE: {
    _DEPOSIT: "ฝาก",
    _QR: "สแกน QR ต่อไปนี้หรือคัดลอกที่อยู่",
    _NETWORK: "เครือข่าย",
    _INSTRUCTIONS: "คำแนะนำในการส่ง TRON (TRX)",
    _STEP1: "ตรวจสอบให้แน่ใจว่าเครือข่ายที่เลือกคือ TRON (TRC20)",
    _STEP2: "ส่งจำนวน TRON (TRX) ไปยังที่อยู่กระเป๋าสตางค์ต่อไปนี้",
    _COPY: "คัดลอก",
    _DISCLAIMER:
      "คำปฏิเสธ: เราไม่รับผิดชอบต่อการทำธุรกรรมนอกเครือข่าย TRON (TRC20) หรือต่อการส่งสกุลเงินดิจิทัลที่ไม่ใช่ TRON (TRX) ความสูญเสียใด ๆ เนื่องจากข้อผิดพลาดเหล่านี้จะเป็นความรับผิดชอบของผู้ส่งแต่เพียงผู้เดียว",
    _TABLE1: "ประวัติการฝาก",
    _TABLE1_A: "คำสั่ง",
    _TABLE1_B: "จำนวนเงิน",
    _TABLE1_C: "วันที่",
    _TABLE1_D: "สถานะ",
    _SUCCESS: "เพิ่มแพ็คเกจสำเร็จแล้ว",
    _COPYMESSAGE: "คัดลอกที่อยู่กระเป๋าสตางค์สำเร็จ",
  },
  _PACKAGES: {
    _ATTENTION:
      "คำเตือน: คุณมีบัญชีองค์กร การซื้อแพ็คเกจจะรีสตาร์ทบัญชีและสิทธิประโยชน์ทั้งหมด",
    _PACKAGES: "เลือกแผนที่ดีที่สุดสำหรับคุณ",
    _SUBTITLE: "คุณมี 8 ตัวเลือก",
    _BALANCE: "ยอดเงินฝาก",
    _PACKAGE: "แพ็คเกจ",
    _PACKAGE_1: "ราคา",
    _PACKAGE_2: "ไบนารี",
    _PACKAGE_3: "ผลตอบแทนรายวัน",
    _BUTTON1: "เต็มแล้ว",
    _BUTTON2: "ซื้อ",
    _RECHARGE: "คุณมีเงินไม่เพียงพอ กรุณาเติมเงินในบัญชีของคุณ",
    _PACKAGEMESSAGE: "คุณต้องซื้อแพ็คเกจที่สูงกว่า",
    _SUCCESS: "เพิ่มแพ็คเกจสำเร็จแล้ว",
  },
  _NETWORK: {
    _LEFT: "ซ้าย",
    _RIGHT: "ขวา",
    _AVAILABLE: "ใช้ได้",
    _WITHDRAW: "ยอดถอนรวม",
    _SEARCHLABEL: "ค้นหาโดยอีเมล",
    _SEARCH: "ค้นหา",
    _NETWORK: "เครือข่าย",
    _DIRECTS: "โดยตรง",
    _SUCCESS: "ค้นหาสำเร็จ",
    _ERROR: "ไม่ใช่ส่วนหนึ่งของต้นไม้ทวินามของคุณ",
  },
  _PROFILE: {
    _TITLE: "โปรไฟล์",
    _NAME: "ชื่อของคุณ",
    _EMAIL: "อีเมลของคุณ",
    _CURRENTPASSWORD: "รหัสผ่านปัจจุบัน",
    _PASSWORD: "รหัสผ่านใหม่",
    _CONFIRMPASSWORD: "ยืนยันรหัสผ่านใหม่",
    _WALLET: "ที่อยู่การถอน",
    _ACTION: "แก้ไข",
    _TIPS: "เคล็ดลับ",
    _TIPS_1: "ชื่อจะต้องอ่านง่ายและไม่เกิน 100 ตัวอักษร",
    _TIPS_2: "รหัสผ่านจะต้องมีตัวเลข ตัวอักษร และสัญลักษณ์ ยิ่งซับซ้อนยิ่งดี",
    _TIPS_3:
      "อีเมลไม่สามารถเปลี่ยนแปลงได้ หากคุณต้องการเปลี่ยนแปลง กรุณาเขียนไปที่ฝ่ายสนับสนุน",
    _TIPS_4:
      "ที่อยู่การถอนสามารถแก้ไขได้เฉพาะกับการยืนยัน 2 ปัจจัย ซึ่งต้องเปิดใช้งานก่อนหน้านี้",
    _ERROR_PASSWORD: "ข้อผิดพลาดของรหัสผ่านก่อนหน้า",
    _SUCCESS: "อัปเดตโปรไฟล์สำเร็จ",
    _MIN: "การถอนขั้นต่ำ",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "ลืมรหัสผ่านของคุณ?",
    _EMAIL: "อีเมลของคุณ",
    _BUTTON: "ส่งอีเมล",
    _MESSAGE: "รหัสผ่านของคุณถูกส่งไปยังอีเมลของคุณ",
    _ERROR_EMAIL: "อีเมลนี้ไม่ได้ลงทะเบียน",
  },
  _DASHBOARD: {
    _TITLE: "ยอดเงินฝาก",
    _TITLE2: "กำไรทั้งหมดของ Gladiaverse",
    _TITLE3: "รายได้แพ็คเกจ",
    _TITLE4: "กำไรจากไบนารี",
    _WELCOME: "ยินดีต้อนรับกลับ",
    _WELCOME2: "รับรองความสำเร็จของคุณในอาร์บิทราจใน 4 ขั้นตอนง่ายๆ",
    _STEP1: "ตั้งค่ากระเป๋าเงินการถอนของคุณ",
    _STEP2: "ซื้อแพ็คเกจ",
    _STEP3: "รับผลกำไรของคุณ",
    _STEP4: "ขยายเครือข่ายของคุณ",
    _TABLE2: "การติดตามการอ้างอิง",
    _LEFT: "ซ้าย",
    _RIGHT: "ขวา",
    _LEFT_BINARY: "ไบนารีซ้าย",
    _RIGHT_BINARY: "ไบนารีขวา",
    _RANDOM_BINARY: "ไบนารีสุ่ม",
    _TABLE3: "รายการแพ็คเกจ",
    _START: "เริ่ม",
    _FINISH: "เสร็จสิ้น",
    _STATUS: "สถานะ",
    _STATUS_ACTIVE: "ใช้งานอยู่",
    _STATUS_INACTIVE: "ไม่ใช้งาน",
    _CURRENT: "ปัจจุบัน",
    _UPGRADE: "อัปเกรด",
    _COPYMESSAGE: "คัดลอกสำเร็จ",
    _NOTIFICATION3: "กำไรจากไบนารี",
    _NOTIFICATION2: "กำไรจากอาร์บิทราจ",
    _NOTIFICATION1: "เงินฝากใหม่",
    _NOTIFICATION5: "ซื้อแพ็คเกจ",
    _SHOW: "กำลังแสดง",
    _SHOW2: "ของ",
    _PREV: "ก่อนหน้า",
    _NEXT: "ถัดไป",
    _TABLE4_A: "การดำเนินงาน",
    _TABLE4_B: "กำไร",
    _TABLE4_C: "ผลตอบแทนการลงทุน",
    _TABLE4_D: "วันที่",
  },
  _VERSE: {
    _QUESTION: "คำถาม",
    _ANSWER: "คำตอบ",
    _EMPTY: "เลือกคำถามเพื่อดูรายละเอียด",
    _INPUT: "เขียนคำถามของคุณที่นี่...",
  },
  _AUTH: {
    _TITLEDEFAULT: "เปลี่ยนกระเป๋าเงินการถอน",
    _TITLE: "ขั้นตอนที่ 1",
    _TITLE2: "ขั้นตอนที่ 2",
    _TITLE3: "ขั้นตอนที่ 3",
    _STEP1: "ดาวน์โหลดและติดตั้งแอปพลิเคชันการยืนยันบนอุปกรณ์ของคุณ",
    _STEP2: "สแกนโค้ดเพื่อบันทึก",
    _FORMEMAIL: "การยืนยันอีเมล",
    _FORMHELPEMAIL: "ป้อนรหัสยืนยัน 6 หลักที่ส่งไปยัง",
    _FORMHELPAUTH: "ป้อนรหัส 6 หลักที่สร้างโดยแอปพลิเคชันการยืนยัน",
    _FORM1: "พินโดยแอปพลิเคชันการยืนยัน",
    _FORM2: "ที่อยู่การถอน",
    _FORMMIN: "ที่อยู่การถอนจะต้องมีความยาว 34 ตัวอักษร",
    _FORMCODESEND: "ส่งรหัส",
    _VERIFF: "การตรวจสอบ",
    _ACTION: "ตรวจสอบและบันทึก",
    _ERROR: "ข้อผิดพลาด ลองอีกครั้ง",
    _SUCCESS: "เปลี่ยนสำเร็จ",
    _RETRYOTP: "การส่งรหัสผิดพลาด ลองอีกครั้ง",
    _SENDOTP: "ส่งรหัสสำเร็จ",
    _SUCCESSOTP: "ยืนยัน OTP สำเร็จ",
    _SUCCESSAUTH2F: "ยืนยันการตรวจสอบ 2 ปัจจัยสำเร็จ",
    _ERRORCODE: "รหัสไม่ถูกต้อง ลองอีกครั้ง",
  },
  _COUNTDOWN: {
    _TITLE: "การตัดครั้งถัดไป:",
    _DAYS: "วัน",
    _HOURS: "ชั่วโมง",
    _MINUTES: "นาที",
    _SECONDS: "วินาที",
  },
  _WITHDRAWAL: {
    _TITLE: "ธุรกรรมการถอน",
  },
};
