export const fra = {
  _LOGIN: {
    _TITLE: "Entrez dans l'arène",
    _SUBTITLE: "Entrez votre email et mot de passe pour vous connecter",
    _EMAIL: "Votre email",
    _PASSWORD: "Votre mot de passe",
    _REMEMBER: "Se souvenir de moi",
    _LOGIN: "Connexion",
    _SIGNUP: "Inscription",
    _FORGOTPASSWORD: "Avez-vous oublié votre mot de passe?",
    _NOACCOUNT: "Vous n'avez pas de compte?",
    _ERROR_EMAIL: "Apparemment, vous n'êtes pas enregistré! Réessayez.",
    _ERROR_PASSWORD: "Mot de passe incorrect",
    _SUCCESS: "Connexion réussie",
  },
  _REGISTER: {
    _REGISTER: "Créer un compte",
    _NAME: "Votre nom",
    _EMAIL: "Votre email",
    _PASSWORD: "Votre mot de passe",
    _CONFIRMPASSWORD: "Confirmer le mot de passe",
    _REFERAL: "Code de parrainage",
    _HAVEACCOUNT: "J'ai un compte",
    _ERROR_REFERAL: "Code de parrainage incorrect",
    _ERROR_EMAIL: "Cet email existe déjà",
    _SUCCESS: "Inscription réussie",
  },
  _SIDEBAR: {
    _PAGES: "Pages",
    _OPEN: "Ouvrir la barre latérale",
    _DASHBOARD: "Tableau de bord",
    _NETWORK: "Réseau",
    _FINANCE: "Finance",
    _PACKAGES: "Forfaits",
    _SIGNOUT: "Déconnexion",
    _LANGUAGE: "Langue",
    _PROFILE: "Profil",
    _LANGUAGEENGLISH: "Anglais",
    _LANGUAGESPANISH: "Espagnol",
    _LANGUAGEFRENCH: "Français",
    _LANGUAGEGERMAN: "Allemand",
    _LANGUAGEPORTUGUESE: "Portugais",
    _LANGUAGECHINESE: "Chinois",
    _LANGUAGEHINDI: "Hindi",
    _LANGUAGERUSSIAN: "Russe",
    _LANGUAGEITALIAN: "Italien",
  },
  _FINANCE: {
    _DEPOSIT: "Dépôt",
    _QR: "Scannez le QR suivant ou copiez l'adresse",
    _NETWORK: "Réseau",
    _INSTRUCTIONS: "Instructions pour envoyer du TRON (TRX)",
    _STEP1: "Assurez-vous que le réseau sélectionné est TRON (TRC20).",
    _STEP2:
      "Envoyez le montant de TRON (TRX) à l'adresse de portefeuille suivante.",
    _COPY: "Copier",
    _DISCLAIMER:
      "Avertissement: Nous ne sommes pas responsables des transactions effectuées en dehors du réseau TRON (TRC20) ou de l'envoi de cryptomonnaies autres que TRON (TRX). Toute perte de fonds due à ces erreurs sera de la seule responsabilité de l'expéditeur.",
    _TABLE1: "Historique des dépôts",
    _TABLE1_A: "Commande",
    _TABLE1_B: "Montant",
    _TABLE1_C: "Date",
    _TABLE1_D: "Statut",
    _SUCCESS: "Le forfait a été ajouté avec succès",
    _COPYMESSAGE: "Adresse de portefeuille copiée avec succès",
  },
  _PACKAGES: {
    _ATTENTION:
      "Attention: Vous avez un compte entreprise. En achetant un forfait, vous réinitialiserez le compte et tous ses avantages.",
    _PACKAGES: "Sélectionnez le meilleur plan pour vous",
    _SUBTITLE: "Vous avez 8 options",
    _BALANCE: "Solde du dépôt",
    _PACKAGE: "Forfait",
    _PACKAGE_1: "Prix",
    _PACKAGE_2: "Binaire",
    _PACKAGE_3: "ROI quotidien",
    _BUTTON1: "Rempli",
    _BUTTON2: "Acheter",
    _RECHARGE: "Vous n'avez pas assez d'argent, rechargez vos finances",
    _PACKAGEMESSAGE: "Vous devez acheter un forfait supérieur",
    _SUCCESS: "Le forfait a été ajouté avec succès",
  },
  _NETWORK: {
    _LEFT: "Gauche",
    _RIGHT: "Droite",
    _AVAILABLE: "Disponible",
    _WITHDRAW: "Total historique",
    _SEARCHLABEL: "Recherche par email",
    _SEARCH: "Chercher",
    _NETWORK: "Réseau",
    _DIRECTS: "Directs",
    _SUCCESS: "Recherche réussie",
    _ERROR: "Ne fait pas partie de votre arbre binaire.",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Votre nom",
    _EMAIL: "Votre email",
    _CURRENTPASSWORD: "Mot de passe actuel",
    _PASSWORD: "Nouveau mot de passe",
    _CONFIRMPASSWORD: "Confirmer le nouveau mot de passe",
    _WALLET: "Adresse de retrait",
    _ACTION: "Modifier",
    _TIPS: "Conseils",
    _TIPS_1: "Le nom doit être lisible et ne pas dépasser 100 caractères.",
    _TIPS_2:
      "Le mot de passe doit inclure des chiffres, des lettres et des symboles ; plus il est complexe, mieux c'est.",
    _TIPS_3:
      "L'email ne peut pas être changé. Si vous avez besoin de le modifier, veuillez écrire au support.",
    _TIPS_4:
      "L'adresse de retrait ne peut être modifiée qu'avec un authentificateur à 2 facteurs, qui doit être activé au préalable.",
    _ERROR_PASSWORD: "Erreur de l'ancien mot de passe",
    _SUCCESS: "Profil mis à jour avec succès",
    _MIN: "Retrait minimum",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Mot de passe oublié?",
    _EMAIL: "Votre email",
    _BUTTON: "Envoyer l'email",
    _MESSAGE: "Votre mot de passe a été envoyé à votre email",
    _ERROR_EMAIL: "Cet email n'est pas enregistré",
  },
  _DASHBOARD: {
    _TITLE: "Solde des dépôts",
    _TITLE2: "Profit total de Gladiaverse",
    _TITLE3: "Gains du forfait",
    _TITLE4: "Profit par Binaire",
    _WELCOME: "Bon retour",
    _WELCOME2: "Assurez votre succès en arbitrage en 4 étapes simples",
    _STEP1: "Configurez votre portefeuille de retrait",
    _STEP2: "Achetez un forfait",
    _STEP3: "Recevez vos profits",
    _STEP4: "Développez votre réseau",
    _TABLE2: "Suivi des parrainages",
    _LEFT: "Gauche",
    _RIGHT: "Droite",
    _LEFT_BINARY: "Binaire gauche",
    _RIGHT_BINARY: "Binaire droite",
    _RANDOM_BINARY: "Binaire aléatoire",
    _TABLE3: "Liste des forfaits",
    _START: "Début",
    _FINISH: "Fin",
    _STATUS: "Statut",
    _STATUS_ACTIVE: "Actif",
    _STATUS_INACTIVE: "Inactif",
    _CURRENT: "Actuel",
    _UPGRADE: "Mise à niveau",
    _COPYMESSAGE: "Copie réussie",
    _NOTIFICATION3: "Profit Binaire",
    _NOTIFICATION2: "Profit d'Arbitrage",
    _NOTIFICATION1: "Nouveau Dépôt",
    _NOTIFICATION5: "Achat de Forfait",
    _SHOW: "Affichage",
    _SHOW2: "de",
    _PREV: "Précédent",
    _NEXT: "Suivant",
    _TABLE4_A: "Opération",
    _TABLE4_B: "Profit",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Date",
  },
  _VERSE: {
    _QUESTION: "Question",
    _ANSWER: "Réponse",
    _EMPTY: "Sélectionnez une question pour voir les détails.",
    _INPUT: "Écrivez votre question ici...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Changer le portefeuille de retrait",
    _TITLE: "Étape 1",
    _TITLE2: "Étape 2",
    _TITLE3: "Étape 3",
    _STEP1: "Télécharger et installer l'authentificateur sur votre appareil",
    _STEP2: "Scanner le code pour enregistrer",
    _FORMEMAIL: "Vérification par e-mail",
    _FORMHELPEMAIL: "Entrez le code de vérification à 6 chiffres envoyé à",
    _FORMHELPAUTH:
      "Entrez le code à 6 chiffres généré par l'application d'authentification",
    _FORM1: "PIN de l'authentificateur",
    _FORM2: "Adresse de retrait",
    _FORMMIN: "L'adresse de retrait doit avoir 34 caractères de longueur",
    _FORMCODESEND: "Envoyer le code",
    _VERIFF: "Vérifier",
    _ACTION: "Vérifier et Enregistrer",
    _ERROR: "Erreur, réessayez.",
    _SUCCESS: "Changement réussi",
    _RETRYOTP: "Erreur lors de l'envoi du code, réessayez.",
    _SENDOTP: "Code envoyé avec succès",
    _SUCCESSOTP: "OTP vérifié avec succès",
    _SUCCESSAUTH2F: "Auth2fa vérifié avec succès",
    _ERRORCODE: "Code invalide, réessayez",
  },
  _COUNTDOWN: {
    _TITLE: "Prochain coupure:",
    _DAYS: "Jours",
    _HOURS: "Heures",
    _MINUTES: "Minutes",
    _SECONDS: "Secondes",
  },
  _WITHDRAWAL: {
    _TITLE: "Transactions de Retrait",
  },
};
