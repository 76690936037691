import React from 'react';

const CustomButton = ({ disabled, text, onClick, isSubmit, fontSize }) => {
    return (
        <div className='custom-button-shadow-two'>
            <div className='custom-button-shadow'>
                <button
                    type={isSubmit ? 'submit' : 'button'}
                    className={`w-full min-w-28 custom-button h-24 y-4 ${fontSize} text-white ${disabled ? 'opacity-10 cursor-not-allowed' : ''}`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    <h1>{text}</h1>
                </button>
            </div>
        </div>
    );
};

export default CustomButton;
