import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import GladiaverseBigCard from "../assets/svg/bigcard.svg"
import Gladiator1 from "../assets/svg/gladitor1.svg"
import Gladiator2 from "../assets/svg/gladiator2.svg"
import { Link, useNavigate } from 'react-router-dom';
import { Doughnut, Bar } from 'react-chartjs-2';
import BackgroundImage from "../assets/img/background.png"
import AppContext from "../context/AppContext";
import toast from "react-hot-toast";
import Countdown from "../components/Countdown";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, ArcElement, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend);

export default function Home() {
  const { t } = useTranslation();
  const { loading, updateLoading } = useContext(AppContext);
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [packagesOperation, setPackagesOperation] = useState([]);
  const [emptyPackage, setEmptyPackage] = useState(false);
  const [hoursServer, setHoursServer] = useState();


  useEffect(() => {
    getUsersData();
    getNotificationsPackages();
  }, []);

  const getUsersData = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response?.data;
        setUserData(data);
        if (data && data.package_value_maximun && data.package_value_current) {
          const max = data.package_value_maximun;
          const current = data.package_value_current;
          const calculatedPercentage = (current / max) * 100;
          setPercentage(calculatedPercentage);
        }
        setHoursServer(response?.hour);
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }


  const getNotificationsPackages = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/notification?limit=10`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response?.data?.items;
        if (data) {
          // Filtrar los paquetes que tienen action = 2 o action = 3
          const filteredData = data.filter(item => item.action === "2" || item.action === "3");
          if (filteredData.length === 0) {
            setEmptyPackage(true);
          } else {
            setPackagesOperation(filteredData);
          }
        } else {
          console.log('vacio');
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  function createGradient(ctx, chartArea, index) {
    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    if (index === 0) {
      // Gradient for the first dataset
      gradient.addColorStop(0, '#05CD99');
      gradient.addColorStop(1, '#CF33CE');
    } else {
      // Gradient for the second dataset
      gradient.addColorStop(0, '#3D3D3D');
      gradient.addColorStop(1, '#CF33CE');
    }
    return gradient;
  }


  const data = {
    labels: [t("_DASHBOARD._LEFT"), t("_DASHBOARD._RIGHT")],
    datasets: [
      {
        data: [userData?.total_binary_left > 0 ? userData?.total_binary_left : 1, userData?.total_binary_right > 0 ? userData?.total_binary_right : 1],
        backgroundColor: (ctx) => {
          const chart = ctx.chart;
          const { ctx: chartCtx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return [
            createGradient(chartCtx, chartArea, 0), // Gradient for the first dataset
            createGradient(chartCtx, chartArea, 1)  // Gradient for the second dataset
          ];
        },
        hoverBackgroundColor: '#303551',
        borderWidth: 0,
      },
    ],
  };

  const options = {
    rotation: -90,
    circumference: 180,
    cutout: '70%',
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.raw} TRX`;
          },
        },
      },
    },
  };


  const copyShared = (code) => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_FRONTEND}/signup?invite=${code}`
    );
    toast.success(t("_DASHBOARD._COPYMESSAGE"));
  };

  const goToPackages = () => {
    navigate("/packages");
  }


  const nameNotification = (name) => {
    if (name === "3") {
      return t("_DASHBOARD._NOTIFICATION3");
    } else if (name === "2") {
      return t("_DASHBOARD._NOTIFICATION2");
    } else if (name === "1") {
      return t("_DASHBOARD._NOTIFICATION1");
    } else if (name === "5") {
      return t("_DASHBOARD._NOTIFICATION5");
    }
  }

  return (
    <div className="container mx-auto lg:p-4">
      {/** Buttons speed */}
      <div className="flex flex-col lg:flex-row gap-4 p-4">
        <div className="w-full lg:w-1/4 p-4 bg-gladiaverse rounded-lg shadow-md text-white">
          <div className="flex justify-between items-center">
            <div >
              <p className="text-sm text-slate-400">{t("_DASHBOARD._TITLE")}</p>
              <p className="">{parseFloat(userData?.balance_deposit)} TRX </p>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                <path d="M2.273 5.625A4.483 4.483 0 0 1 5.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 3H5.25a3 3 0 0 0-2.977 2.625ZM2.273 8.625A4.483 4.483 0 0 1 5.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 6H5.25a3 3 0 0 0-2.977 2.625ZM5.25 9a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3H15a.75.75 0 0 0-.75.75 2.25 2.25 0 0 1-4.5 0A.75.75 0 0 0 9 9H5.25Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/4 p-4 bg-gladiaverse rounded-lg shadow-md text-white">
          <div className="flex justify-between items-center">
            <div >
              <p className="text-sm text-slate-400">{t("_DASHBOARD._TITLE2")}</p>
              <p className="">{parseFloat(userData?.total_available) + parseFloat(userData?.total_binary)} TRX {/*  <span className="text-green-500 text-sm ">+55%</span> */}</p>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                <path fill-rule="evenodd" d="M8.25 3.75H19.5a.75.75 0 0 1 .75.75v11.25a.75.75 0 0 1-1.5 0V6.31L5.03 20.03a.75.75 0 0 1-1.06-1.06L17.69 5.25H8.25a.75.75 0 0 1 0-1.5Z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/4 p-4 bg-gladiaverse rounded-lg shadow-md text-white">
          <div className="flex justify-between items-center">
            <div >
              <p className="text-sm text-slate-400">{t("_DASHBOARD._TITLE3")}</p>
              <p className="">{userData?.total_available} TRX {userData?.balance_available > 0 && (<><span className="text-green-500 text-sm ">+{userData?.balance_available} TRX</span></>)}</p>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                <path d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/4 p-4 bg-gladiaverse rounded-lg shadow-md text-white">
          <div className="flex justify-between items-center">
            <div >
              <p className="text-sm text-slate-400">{t("_DASHBOARD._TITLE4")}</p>
              <p className="">{userData?.total_binary} TRX  {userData?.balance_binary > 0 && (<><span className="text-green-500 text-sm ">+{userData?.balance_binary} TRX</span></>)} </p>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9 p-1.5 rounded-lg text-violet-800 bg-slate-950 transition duration-75 group-hover:bg-violet-800 group-hover:text-white group-hover:font-bold">
                <path fill-rule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clip-rule="evenodd" />
                <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/** Welcome and Referral Tracking*/}
      <div className="flex flex-col lg:flex-row gap-4 p-4 lg:my-1">
        <div className="w-full lg:w-1/2 bg-gladiaverse rounded-lg shadow-md text-white p-4 ">
          <div className="flex justify-between relative p-4 h-auto lg:h-60 ">
            <div className="w-full lg:w-1/2 space-y-1">
              <p className="text-sm text-slate-400">{t("_DASHBOARD._WELCOME")}</p>
              <p className="text-xl">
                {userData?.name
                  ? userData.name.length > 15
                    ? `${userData.name.slice(0, 15)}...`
                    : userData.name.charAt(0).toUpperCase() + userData.name.slice(1)
                  : ""}
              </p>
              <p className="text-sm text-slate-400">{t("_DASHBOARD._WELCOME2")}</p>
              <ul className="text-sm">
                <li className={`flex items-center ${userData?.withdrawal_address ? "text-green-500" : "text-gray-500 cursor-pointer hover:underline"}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <Link to="/auth">{t("_DASHBOARD._STEP1")}</Link>
                </li>
                <li className={`flex items-center ${userData?.package_price !== "0" ? "text-green-500" : "text-gray-500 cursor-pointer hover:underline"}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <Link to="/packages">{t("_DASHBOARD._STEP2")}</Link>
                </li>
                <li className={`flex items-center ${userData?.total_available !== "0" ? "text-green-500" : "text-gray-500 cursor-pointer hover:underline"}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  {t("_DASHBOARD._STEP3")}
                </li>
                <li className={`flex items-center ${userData?.total_binary !== "0" ? "text-green-500" : "text-gray-500 cursor-pointer hover:underline"}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  {t("_DASHBOARD._STEP4")}
                </li>
              </ul>
            </div>
            <img src={GladiaverseBigCard} className="hidden lg:flex absolute right-0 -bottom-3 w-1 w-6/12 " alt="Gladiaverse Big card" />
          </div>
        </div>
        <div className="w-full lg:w-1/2 bg-gladiaverse rounded-lg shadow-md text-white  p-4">
          <div className="flex justify-between flex-col lg:flex-row relative p-4 h-auto lg:h-60 ">
            <div className="w-full lg:w-3/5 space-y-1">
              <p className="text-xl">{t("_DASHBOARD._TABLE2")}</p>
              <div className="flex flex-col lg:flex-row gap-2">
                <div className="space-y-2 w-auto">
                  <p>{t("_DASHBOARD._LEFT_BINARY")}</p>
                  <div className="w-full max-w-[8.5rem]">
                    <div className="relative">
                      <label for="npm-install-copy-text" className="sr-only">Label</label>
                      <input id="npm-install-copy-text" type="text" className="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" value={userData?.referred_my_left || "Loading"} disabled readonly />
                      <button data-copy-to-clipboard-target="npm-install-copy-text" className="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-400  hover:bg-gray-100 dark:bg-gray-800 hover:bg-gray-700 border-gray-600  rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border" onClick={() => copyShared(userData?.referred_my_left)}>
                        <span id="default-message" className="inline-flex items-center">
                          <svg className="w-3 h-3 me-1.2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="space-y-2 w-auto">
                  <p>{t("_DASHBOARD._RIGHT_BINARY")}</p>
                  <div className="w-full max-w-[8.5rem]">
                    <div className="relative">
                      <label for="npm-install-copy-text" className="sr-only">Label</label>
                      <input id="npm-install-copy-text" type="text" className="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" value={userData?.referred_my_right || "Loading"} disabled readonly />
                      <button data-copy-to-clipboard-target="npm-install-copy-text" className="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-400  hover:bg-gray-100 dark:bg-gray-800 hover:bg-gray-700 border-gray-600  rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border" onClick={() => copyShared(userData?.referred_my_right)}>
                        <span id="default-message" className="inline-flex items-center">
                          <svg className="w-3 h-3 me-1.2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-2 w-auto">
                <p>{t("_DASHBOARD._RANDOM_BINARY")}</p>
                <div className="w-full max-w-[8.5rem]">
                  <div className="relative">
                    <label for="npm-install-copy-text" className="sr-only">Label</label>
                    <input id="npm-install-copy-text" type="text" className="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" value={userData?.referred_my_random || "Loading"} disabled readonly />
                    <button data-copy-to-clipboard-target="npm-install-copy-text" className="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-400  hover:bg-gray-100 dark:bg-gray-800 hover:bg-gray-700 border-gray-600  rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border" onClick={() => copyShared(userData?.referred_my_random)}>
                      <span id="default-message" className="inline-flex items-center">
                        <svg className="w-3 h-3 me-1.2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Doughnut data={data} options={options} className="w-full lg:w-2/5" />
            </div>
          </div>
        </div>
      </div >
      {/** Cuenta regresiva  + Disponibilidad*/}
      {userData?.active && (
        <div className="flex flex-col lg:flex-row gap-4 p-4 lg:my-1">
          <div className="w-full min-h-34 rounded-lg flex flex-col p-4 gap-2 bg-cover bg-center bg-coutndown relative"  >
            <Countdown targetDate={userData?.activeDate} dateServer={hoursServer} />
          </div>
        </div >
      )}
      {/** Mi Package Barra */}
      <div className="flex flex-col lg:flex-row gap-4 p-4  h-auto ">
        <div className="w-full bg-gladiaverse rounded-lg shadow-md h-auto text-white p-4">
          <div className="flex justify-between items-center">
            <p className="text-xl text-white">{t("_DASHBOARD._TABLE3")}</p>
            {/* 
            <button className="bg-violet-500 text-white px-4 py-2 rounded-lg hover:bg-violet-600 transition duration-200">
              Mis Paquetes
            </button>
            */}
          </div>
          <div className="mx-5 flex flex-row justify-between my-5">
            <p>{t("_DASHBOARD._START")}: 0 TRX</p>
            <p>{t("_DASHBOARD._FINISH")}: {userData?.package_value_maximun || 0} TRX</p>
          </div>
          <div className="relative h-14 rounded-2xl overflow-hidden bg-gray-300 my-5 mx-5">
            <div className="absolute top-0 h-14 rounded-2xl shim-green"
              style={{ width: `${percentage}%` }}>
            </div>
          </div>
          <div className="mx-5 flex flex-row justify-between my-5">
            <p>GV{userData?.package} - {t("_DASHBOARD._STATUS")}: {userData?.active === "1" ? t("_DASHBOARD._STATUS_ACTIVE") : t("_DASHBOARD._STATUS_INACTIVE")}</p>
            <p> {t("_DASHBOARD._CURRENT")}: {userData?.package_value_current ? userData?.package_value_current : "0"} TRX - {percentage ? percentage.toFixed(2) : "0"}%</p>
          </div>
          {userData?.package !== "8" && (
            <div className="mx-5 flex flex-row justify-between my-5">
              <button className="text-md w-full border-2 p-1 cursor-pointer rounded-lg" onClick={() => goToPackages()}>{t("_DASHBOARD._UPGRADE")} </button>
            </div>
          )}
        </div>
      </div>
      {/** Mi Profits Package List */}
      {
        userData?.active && (
          <div className=" gap-4 p-4 h-auto ">
            <div className="w-full bg-gladiaverse rounded-lg shadow-md h-auto text-white p-4">
              <div className="mx-5 flex flex-row justify-between my-5 items-center">
                <h2>{t("_DASHBOARD._TITLE2")}</h2>
                <Link to="/withdrawal">
                  <button className="text-md border-2 py-2 px-4 cursor-pointer rounded-lg" > {t("_WITHDRAWAL._TITLE")}</button>
                </Link>
              </div>
              <div className="flex flex-col lg:flex-row gap-4 p-4 h-auto w-full">
                <div className="relative overflow-x-auto w-full">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
                    <thead className="text-xs text-gray-400 uppercase bg-gray-800 ">
                      <tr>
                        <th className="px-6 py-3">
                          {t("_DASHBOARD._TABLE4_A")}
                        </th>
                        <th className="px-6 py-3">
                          {t("_DASHBOARD._TABLE4_B")}
                        </th>
                        <th className="px-6 py-3">
                          {t("_DASHBOARD._TABLE4_C")}
                        </th>
                        <th className="px-6 py-3">
                          {t("_DASHBOARD._TABLE4_D")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {packagesOperation.map((item, index) => (
                        <tr className="border-b border-gray-700" key={index}>
                          <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                            {nameNotification(item?.action)}
                          </th>
                          <td className="px-6 py-4">
                            {item?.value ? item.value : 0} TRX
                          </td>
                          <td className="px-6 py-4">
                            {item?.message ? item.message + "%" : "-"}
                          </td>
                          <td className="px-6 py-4">
                            {item?.created ? item.created : "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">{t("_DASHBOARD._SHOW")} <span className="font-semibold text-gray-900 dark:text-white mx-1">1-10</span> {t("_DASHBOARD._SHOW2")} <span className="ml-1 font-semibold text-gray-900 dark:text-white">{packagesOperation.length}</span></span>
                    <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                      <li>
                        <a href="#" className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{t("_DASHBOARD._PREV")}</a>
                      </li>
                      {/*  
                  <li>
                    <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">2</a>
                  </li>
                  <li>
                    <a href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">4</a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">5</a>
                  </li>
                    */}
                      <li>
                        <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{t("_DASHBOARD._NEXT")}</a>
                      </li>

                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div >
  );
}
