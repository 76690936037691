import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import Send from "../assets/svg/send.svg"
import BigMan from "../assets/svg/bigman.svg"
import questions from "../questions.json"

export default function VerseGPT() {
  const { t } = useTranslation();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [newQuestion, setNewQuestion] = useState(null);
  const [allQuestions, setAllQuestions] = useState(questions);
  const [newQuestionID, setNewQuestionID] = useState(allQuestions.length + 1);

  const newQuestionChange = () => {
    const newEntry = {
      id: newQuestionID,
      question: newQuestion,
      answers: ""
    };
    setAllQuestions([...allQuestions, newEntry])
    setNewQuestionID(newQuestionID + 1);
    setNewQuestion("");
  }

  return (
    <div className="bg-gladiaverse flex rounded-lg shadow-md text-white flex-col h-full lg:h-screen gap-2 p-4 lg:my-1 m-4">
      <div className="flex flex-col lg:flex-row gap-4 lg:my-1 h-full">
        <div className="bg-gladiaverse2 rounded-lg shadow-md text-white p-2 lg:w-1/6 overflow-auto">
          <ul className="flex flex-col gap-1">
            {allQuestions.map(item => (
              <li key={item.id} onClick={() => setSelectedQuestion(item)} className={selectedQuestion?.id === item.id ? 'bg-slate-900 cursor-pointer text-xs p-3 rounded-lg truncate' : 'cursor-pointer text-xs p-3 rounded-lg truncate'}>
                {item.question}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col w-full lg:w-5/6">
          <div className="w-full bg-gladiaverse2 relative rounded-lg shadow-md text-white p-4  lg:h-5/6">
            <div className="lg:h-1/6">
              {selectedQuestion &&
                <div className="lg:w-4/6 p-4">
                  <p className="text-sm italic text-white mb-2 font-semibold">{t("_VERSE._QUESTION")} {selectedQuestion.id}</p>
                  <p className="text-sm text-xs font-light text-slate-400">{selectedQuestion.question}</p>
                </div>
              }
            </div>
            <div className="flex justify-between p-4 h-full lg:h-5/6">
              <div className="w-full lg:w-1/2 space-y-1">

                {selectedQuestion ? (
                  <div className="h-full overflow-auto">
                    <p className="text-sm italic text-white mb-2 font-semibold">{t("_VERSE._ANSWER")}:</p>
                    <p className="text-sm text-xs font-light text-slate-400 h-auto lg:h-60">{selectedQuestion.answers}</p>
                  </div>
                ) : (
                  <p>{t("_VERSE._EMPTY")}</p>
                )}
                {/* <p className="text-sm text-slate-400">Welcome to Black</p>
                <p className="text-sm text-slate-400">Asegura tu éxito en el arbitraje en 4 pasos simples</p>
                <ul className="text-sm">
                  <li className="flex items-center text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Configura tu wallet de retiro
                  </li>
                  <li className="flex items-center text-gray-500 cursor-pointer hover:underline">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Adquiere un paquete de arbitraje en pool
                  </li>
                  <li className="flex items-center text-gray-500 cursor-pointer hover:underline">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Recibe tus ganancias
                  </li>
                  <li className="flex items-center text-gray-500 cursor-pointer hover:underline">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Expande tu network
                  </li>
                </ul> */}
              </div>
              <img src={BigMan} className="hidden lg:flex absolute right-0 bottom-0 w-6/12 " />
            </div>
          </div>
          <div className="w-full flex flex-col bg-gladiaverse2 rounded-lg shadow-md text-white p-4 mt-6">
            <p className="text-sm italic text-white mb-2 font-semibold">{t("_VERSE._QUESTION")} {newQuestionID}</p>
            <div className="flex">
              <textarea className="w-11/12 bg-transparent text-slate-400 font-light text-xs" placeholder={t("_VERSE._INPUT")} name="question" id="question" value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)}></textarea>
              <div className="w-1/12 flex justify-center cursor-pointer" onClick={() => newQuestionChange()}>
                <img src={Send} alt="" className="w-1/2" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
