export const ger = {
  _LOGIN: {
    _TITLE: "Betreten Sie die Arena",
    _SUBTITLE: "Geben Sie Ihre E-Mail und Ihr Passwort ein, um sich anzumelden",
    _EMAIL: "Ihre E-Mail",
    _PASSWORD: "Ihr Passwort",
    _REMEMBER: "Mich erinnern",
    _LOGIN: "Anmelden",
    _SIGNUP: "Registrieren",
    _FORGOTPASSWORD: "Haben Sie Ihr Passwort vergessen?",
    _NOACCOUNT: "Haben Sie kein Konto?",
    _ERROR_EMAIL:
      "Anscheinend sind Sie nicht registriert! Versuchen Sie es erneut.",
    _ERROR_PASSWORD: "Falsches Passwort",
    _SUCCESS: "Erfolgreich angemeldet",
  },
  _REGISTER: {
    _REGISTER: "Konto registrieren",
    _NAME: "Ihr Name",
    _EMAIL: "Ihre E-Mail",
    _PASSWORD: "Ihr Passwort",
    _CONFIRMPASSWORD: "Passwort bestätigen",
    _REFERAL: "Empfehlungscode",
    _HAVEACCOUNT: "Ich habe ein Konto",
    _ERROR_REFERAL: "Falscher Empfehlungscode",
    _ERROR_EMAIL: "Diese E-Mail existiert bereits",
    _SUCCESS: "Registrierung erfolgreich",
  },
  _SIDEBAR: {
    _PAGES: "Seiten",
    _OPEN: "Seitenleiste öffnen",
    _DASHBOARD: "Dashboard",
    _NETWORK: "Netzwerk",
    _FINANCE: "Finanzen",
    _PACKAGES: "Pakete",
    _SIGNOUT: "Abmelden",
    _LANGUAGE: "Sprache",
    _PROFILE: "Profil",
    _LANGUAGEENGLISH: "Englisch",
    _LANGUAGESPANISH: "Spanisch",
    _LANGUAGEFRENCH: "Französisch",
    _LANGUAGEGERMAN: "Deutsch",
    _LANGUAGEPORTUGUESE: "Portugiesisch",
    _LANGUAGECHINESE: "Chinesisch",
    _LANGUAGEHINDI: "Hindi",
    _LANGUAGERUSSIAN: "Russisch",
    _LANGUAGEITALIAN: "Italienisch",
  },
  _FINANCE: {
    _DEPOSIT: "Einzahlung",
    _QR: "Scannen Sie den folgenden QR-Code oder kopieren Sie die Adresse",
    _NETWORK: "Netzwerk",
    _INSTRUCTIONS: "Anweisungen zum Senden von TRON (TRX)",
    _STEP1:
      "Stellen Sie sicher, dass das ausgewählte Netzwerk TRON (TRC20) ist.",
    _STEP2:
      "Senden Sie die Menge an TRON (TRX) an die folgende Wallet-Adresse.",
    _COPY: "Kopieren",
    _DISCLAIMER:
      "Haftungsausschluss: Wir sind nicht verantwortlich für Transaktionen, die außerhalb des TRON (TRC20) Netzwerks durchgeführt werden, oder für das Senden von Kryptowährungen, die nicht TRON (TRX) sind. Jeder Verlust von Geldern aufgrund dieser Fehler liegt in der alleinigen Verantwortung des Absenders.",
    _TABLE1: "Einzahlungshistorie",
    _TABLE1_A: "Bestellung",
    _TABLE1_B: "Menge",
    _TABLE1_C: "Datum",
    _TABLE1_D: "Status",
    _SUCCESS: "Das Paket wurde erfolgreich hinzugefügt",
    _COPYMESSAGE: "Wallet-Adresse erfolgreich kopiert",
  },
  _PACKAGES: {
    _ATTENTION:
      "Achtung: Sie haben ein Firmenkonto. Durch den Kauf eines Pakets wird das Konto und alle seine Vorteile zurückgesetzt.",
    _PACKAGES: "Wählen Sie den besten Plan für Sie",
    _SUBTITLE: "Sie haben 8 Optionen",
    _BALANCE: "Einzahlungsguthaben",
    _PACKAGE: "Paket",
    _PACKAGE_1: "Preis",
    _PACKAGE_2: "Binär",
    _PACKAGE_3: "Tägliche Rendite",
    _BUTTON1: "Gefüllt",
    _BUTTON2: "Kaufen",
    _RECHARGE: "Sie haben nicht genug Geld, laden Sie Ihre Finanzen auf",
    _PACKAGEMESSAGE: "Sie müssen ein höheres Paket kaufen",
    _SUCCESS: "Das Paket wurde erfolgreich hinzugefügt",
  },
  _NETWORK: {
    _LEFT: "Links",
    _RIGHT: "Rechts",
    _AVAILABLE: "Verfügbar",
    _WITHDRAW: "Historisch insgesamt",
    _SEARCHLABEL: "Suche per E-Mail",
    _SEARCH: "Suchen",
    _NETWORK: "Netzwerk",
    _DIRECTS: "Direkt",
    _SUCCESS: "Suche erfolgreich",
    _ERROR: "Nicht Teil deines binären Baums.",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Ihr Name",
    _EMAIL: "Ihre E-Mail",
    _CURRENTPASSWORD: "Aktuelles Passwort",
    _PASSWORD: "Neues Passwort",
    _CONFIRMPASSWORD: "Neues Passwort bestätigen",
    _WALLET: "Abhebungsadresse",
    _ACTION: "Bearbeiten",
    _TIPS: "Tipps",
    _TIPS_1:
      "Der Name muss lesbar sein und darf 100 Zeichen nicht überschreiten.",
    _TIPS_2:
      "Das Passwort muss Zahlen, Buchstaben und Symbole enthalten; je komplexer, desto besser.",
    _TIPS_3:
      "Die E-Mail kann nicht geändert werden. Wenn Sie eine Änderung vornehmen müssen, schreiben Sie bitte an den Support.",
    _TIPS_4:
      "Die Abhebungsadresse kann nur mit einem 2-Faktor-Authentifikator geändert werden, der zuvor aktiviert sein muss.",
    _ERROR_PASSWORD: "Fehler im vorherigen Passwort",
    _SUCCESS: "Profil erfolgreich aktualisiert",
    _MIN: "Mindestabhebung",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Passwort vergessen?",
    _EMAIL: "Ihre E-Mail",
    _BUTTON: "E-Mail senden",
    _MESSAGE: "Ihr Passwort wurde an Ihre E-Mail gesendet",
    _ERROR_EMAIL: "Diese E-Mail ist nicht registriert",
  },
  _DASHBOARD: {
    _TITLE: "Einzahlungsguthaben",
    _TITLE2: "Gesamtgewinn des Gladiaverse",
    _TITLE3: "Paketeinnahmen",
    _TITLE4: "Gewinn durch Binär",
    _WELCOME: "Willkommen zurück",
    _WELCOME2:
      "Sichern Sie Ihren Erfolg in der Arbitrage in 4 einfachen Schritten",
    _STEP1: "Richten Sie Ihre Abhebungswallet ein",
    _STEP2: "Kaufen Sie ein Paket",
    _STEP3: "Erhalten Sie Ihre Gewinne",
    _STEP4: "Erweitern Sie Ihr Netzwerk",
    _TABLE2: "Verfolgung von Empfehlungen",
    _LEFT: "Links",
    _RIGHT: "Rechts",
    _LEFT_BINARY: "Linker Binär",
    _RIGHT_BINARY: "Rechter Binär",
    _RANDOM_BINARY: "Zufälliger Binär",
    _TABLE3: "Paketenliste",
    _START: "Start",
    _FINISH: "Ende",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Aktiv",
    _STATUS_INACTIVE: "Inaktiv",
    _CURRENT: "Aktuell",
    _UPGRADE: "Upgrade",
    _COPYMESSAGE: "Erfolgreich kopiert",
    _NOTIFICATION3: "Binärer Gewinn",
    _NOTIFICATION2: "Arbitrage Gewinn",
    _NOTIFICATION1: "Neue Einzahlung",
    _NOTIFICATION5: "Paket kaufen",
    _SHOW: "Zeigt",
    _SHOW2: "von",
    _PREV: "Vorherige",
    _NEXT: "Nächste",
    _TABLE4_A: "Operation",
    _TABLE4_B: "Gewinn",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Datum",
  },
  _VERSE: {
    _QUESTION: "Frage",
    _ANSWER: "Antwort",
    _EMPTY: "Wählen Sie eine Frage aus, um Details zu sehen.",
    _INPUT: "Schreiben Sie Ihre Frage hier...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Ändern der Auszahlungsbrieftasche",
    _TITLE: "Schritt 1",
    _TITLE2: "Schritt 2",
    _TITLE3: "Schritt 3",
    _STEP1:
      "Laden Sie den Authentifikator auf Ihr Gerät herunter und installieren Sie ihn",
    _STEP2: "Scannen Sie den Code zum Speichern",
    _FORMEMAIL: "E-Mail-Verifizierung",
    _FORMHELPEMAIL:
      "Geben Sie den 6-stelligen Verifizierungscode ein, der gesendet wurde an",
    _FORMHELPAUTH:
      "Geben Sie den 6-stelligen Code ein, der von der Authenticator-App generiert wurde",
    _FORM1: "PIN des Authenticators",
    _FORM2: "Auszahlungsadresse",
    _FORMMIN: "Die Auszahlungsadresse muss 34 Zeichen lang sein",
    _FORMCODESEND: "Code senden",
    _VERIFF: "Überprüfen",
    _ACTION: "Überprüfen und Speichern",
    _ERROR: "Fehler, bitte versuchen Sie es erneut.",
    _SUCCESS: "Änderung erfolgreich",
    _RETRYOTP: "Fehler beim Senden des Codes, bitte versuchen Sie es erneut.",
    _SENDOTP: "Code erfolgreich gesendet",
    _SUCCESSOTP: "OTP erfolgreich verifiziert",
    _SUCCESSAUTH2F: "Auth2fa erfolgreich verifiziert",
    _ERRORCODE: "Ungültiger Code, bitte versuchen Sie es erneut",
  },
  _COUNTDOWN: {
    _TITLE: "Nächster Schnitt:",
    _DAYS: "Tage",
    _HOURS: "Stunden",
    _MINUTES: "Minuten",
    _SECONDS: "Sekunden",
  },
  _WITHDRAWAL: {
    _TITLE: "Abhebungstransaktionen",
  },
};
