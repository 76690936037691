export const eng = {
  _LOGIN: {
    _TITLE: "Enter the arena",
    _SUBTITLE: "Enter your email and password to sign in",
    _EMAIL: "Your email",
    _PASSWORD: "Your password",
    _REMEMBER: "Remember me",
    _LOGIN: "Login",
    _SIGNUP: "Sign up",
    _FORGOTPASSWORD: "Did you forget your password?",
    _NOACCOUNT: "Don't have an account?",
    _ERROR_EMAIL: "Apparently you are not registered! Try again.",
    _ERROR_PASSWORD: "Incorrect password",
    _SUCCESS: "Login Success",
  },
  _REGISTER: {
    _REGISTER: "Register Account",
    _NAME: "Your Name",
    _EMAIL: "Your email",
    _PASSWORD: "Your password",
    _CONFIRMPASSWORD: "Confirm Password",
    _REFERAL: "Referral Code",
    _HAVEACCOUNT: "I have an account",
    _ERROR_REFERAL: "Wrong referral code",
    _ERROR_EMAIL: "This email already exists",
    _SUCCESS: "Registration Success",
  },
  _SIDEBAR: {
    _PAGES: "Pages",
    _OPEN: "Open sidebar",
    _DASHBOARD: "Dashboard",
    _NETWORK: "Network",
    _FINANCE: "Finance",
    _PACKAGES: "Packages",
    _SIGNOUT: "Sign Out",
    _LANGUAGE: "Language",
    _PROFILE: "Profile",
    _LANGUAGEENGLISH: "English",
    _LANGUAGESPANISH: "Español",
    _LANGUAGEFRENCH: "Français",
    _LANGUAGEGERMAN: "Deutsch",
    _LANGUAGEPORTUGUESE: "Portuguese",
    _LANGUAGECHINESE: "Chinese",
    _LANGUAGEHINDI: "Hindi",
    _LANGUAGERUSSIAN: "Russian",
    _LANGUAGEITALIAN: "Italian",
  },
  _FINANCE: {
    _DEPOSIT: "Deposit",
    _QR: "Scan the following QR or copy the address",
    _NETWORK: "Network",
    _INSTRUCTIONS: "Instructions for Sending TRON (TRX)",
    _STEP1: "Make sure the selected network is TRON (TRC20).",
    _STEP2: "Send the amount of TRON (TRX) to the following wallet address.",
    _COPY: "Copy",
    _DISCLAIMER:
      "Disclaimer: We are not responsible for transactions made outside the TRON (TRC20) network or for sending cryptocurrencies other than TRON (TRX). Any loss of funds due to these errors will be the sole responsibility of the sender.",
    _TABLE1: "Deposit History",
    _TABLE1_A: "Order",
    _TABLE1_B: "Amount",
    _TABLE1_C: "Date",
    _TABLE1_D: "Status",
    _SUCCESS: "The package was added successfully",
    _COPYMESSAGE: "Wallet address copied successfully",
  },
  _PACKAGES: {
    _ATTENTION:
      "Attention: You have a corporate account. By purchasing a package, you will restart the account and all its benefits.",
    _PACKAGES: "Select the best plan for you",
    _SUBTITLE: "You have 8 options",
    _BALANCE: "Balance Deposit",
    _PACKAGE: "Package ",
    _PACKAGE_1: "Price",
    _PACKAGE_2: "Binary",
    _PACKAGE_3: "Daily ROI",
    _BUTTON1: "Filled",
    _BUTTON2: "Buy",
    _RECHARGE: "You don't have enough money, recharge your finances",
    _PACKAGEMESSAGE: "You must purchase a higher package",
    _SUCCESS: "The package was added successfully",
  },
  _NETWORK: {
    _LEFT: "Left",
    _RIGHT: "Right",
    _AVAILABLE: "Available",
    _WITHDRAW: "Total Withdrawn",
    _SEARCHLABEL: "Email Search",
    _SEARCH: "Search",
    _NETWORK: "Network",
    _DIRECTS: "Directs",
    _SUCCESS: "Search Successful",
    _ERROR: "Not part of your binary tree.",
  },
  _PROFILE: {
    _TITLE: "Profile",
    _NAME: "Your Name",
    _EMAIL: "Your email",
    _CURRENTPASSWORD: "Current password",
    _PASSWORD: "New password",
    _CONFIRMPASSWORD: "Confirm new password",
    _WALLET: "Withdrawal Address",
    _ACTION: "Edit",
    _TIPS: "Tips",
    _TIPS_1: "The name must be legible and not exceed 100 characters.",
    _TIPS_2:
      "The password must include numbers, letters, and symbols; the more complex, the better.",
    _TIPS_3:
      "The email cannot be changed. If you need to make a change, please write to support.",
    _TIPS_4:
      "The withdrawal address can only be modified with a 2-factor authenticator, which must be enabled previously.",
    _ERROR_PASSWORD: "Previous password error",
    _SUCCESS: "Profile updated successfully",
    _MIN: "Minimum withdrawal",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Forgot your password?",
    _EMAIL: "Your email",
    _BUTTON: "Send email",
    _MESSAGE: "Your password was sent to your email",
    _ERROR_EMAIL: "This email is not registered",
  },
  _DASHBOARD: {
    _TITLE: "Deposit Balance",
    _TITLE2: "Gladiaverse total profit",
    _TITLE3: "Package earnings",
    _TITLE4: "Profit by Binary",
    _WELCOME: "Welcome back",
    _WELCOME2: "Ensure your success in arbitration in 4 simple steps",
    _STEP1: "Set up your withdrawal wallet",
    _STEP2: "Purchase a package",
    _STEP3: "Receive your profits",
    _STEP4: "Expand your network",
    _TABLE2: "Referral Tracking",
    _LEFT: "Left",
    _RIGHT: "Right",
    _LEFT_BINARY: "Left binary",
    _RIGHT_BINARY: "Right binary",
    _RANDOM_BINARY: "Random binary",
    _TABLE3: "Packages List",
    _START: "Start",
    _FINISH: "Finish",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Active",
    _STATUS_INACTIVE: "Inactive",
    _CURRENT: "Current",
    _UPGRADE: "Upgrade",
    _COPYMESSAGE: "Copy successful",
    _NOTIFICATION3: "Binary Profit",
    _NOTIFICATION2: "Arbitrage Profit",
    _NOTIFICATION1: "New Deposit",
    _NOTIFICATION5: "Purchase Package",
    _SHOW: "Showing",
    _SHOW2: "of",
    _PREV: "Previous",
    _NEXT: "Next",
    _TABLE4_A: "Operation",
    _TABLE4_B: "Profit",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Date",
  },
  _VERSE: {
    _QUESTION: "Question",
    _ANSWER: "Answer",
    _EMPTY: "Select a question to see details.",
    _INPUT: "Write your question here...",
  },
  _AUTH: {
    _TITLEDEFAULT: "Change withdrawal wallet",
    _TITLE: "Step 1",
    _TITLE2: "Step 2",
    _TITLE3: "Step 3",
    _STEP1: "Download and install the authenticator on your device",
    _STEP2: "Scan the code to save",
    _FORMEMAIL: "Email Verification",
    _FORMHELPEMAIL: "Enter the 6-digit verification code sent to",
    _FORMHELPAUTH: "Enter the 6-digit code generated by the Authenticator App",
    _FORM1: "Pin by Authenticator",
    _FORM2: "Withdrawal address",
    _FORMMIN: "The withdrawal address must be 34 characters long",
    _FORMCODESEND: "Code Send",
    _VERIFF: "Veriff",
    _ACTION: "Check and Save",
    _ERROR: "Error, try again.",
    _SUCCESS: "Change successful",
    _RETRYOTP: "Error sending the code, try again.",
    _SENDOTP: "Code sent successfully",
    _SUCCESSOTP: "OTP Satisfactorily Verified",
    _SUCCESSAUTH2F: "Auth2fa Satisfactorily Verified",
    _ERRORCODE: "Invalid code try again",
  },
  _COUNTDOWN: {
    _TITLE: "Next cut:",
    _DAYS: "Days",
    _HOURS: "Hours",
    _MINUTES: "Minutes",
    _SECONDS: "Seconds",
  },
  _WITHDRAWAL: {
    _TITLE: "Withdrawal Transactions",
  },
};
