import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";
import Coliseo from "../assets/svg/coliseo.svg"
import Packagenull from "../assets/svg/empty.svg"
import Package500 from "../assets/svg/1.svg"
import Package1000 from "../assets/svg/2.svg"
import Package2000 from "../assets/svg/3.svg"
import Package4000 from "../assets/svg/4.svg"
import Package8000 from "../assets/svg/5.svg"
import Package16000 from "../assets/svg/6.svg"
import Package32000 from "../assets/svg/7.svg"
import Package64000 from "../assets/svg/8.svg"

export default function Network() {
  const { t } = useTranslation();
  const [tab, setTab] = useState("network");
  const [dataUser, setDatauser] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [lefttDirects, setLeftDirects] = useState([]);
  const [rightDirects, setRighDirects] = useState([]);
  const [randomDirects, setRandomDirects] = useState([]);
  const [leftChild, setLeftChild] = useState([]);
  const [rightChild, setRightChild] = useState([]);
  const [rightGrandChildren, setRightGrandChildren] = useState([]);
  const [leftGrandChildren, setLeftGrandChildren] = useState([]);
  const { loading, updateLoading } = useContext(AppContext);
  const [myUserID] = useState(JSON.parse(localStorage.getItem("id")));

  useEffect(() => {
    getUsersData();
    getDirects();
  }, []);

  const getUsersData = (searchUser = "") => {
    if (parseInt(searchUser) < parseInt(myUserID)) {
      return
    }
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users-nodes?user=${searchUser}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setDatauser(response?.data?.user);
        setLeftChild(response?.data?.left || null);
        setRightChild(response?.data?.right || null);
        setRightGrandChildren(response?.data?.right || []);
        setLeftGrandChildren(response?.data?.left || []);
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const getUsersDataEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(searchEmail)) {
      toast.error(t("_NETWORK._ERROR"));
      return;
    }
    updateLoading(true);
    const formData = new FormData();
    formData.append("email", searchEmail);
    fetch(`${process.env.REACT_APP_BACKEND}/users-nodes`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === false) {
          toast.error(t("_NETWORK._ERROR"));
        } else {
          setDatauser(response?.data?.user);
          setLeftChild(response?.data?.left || null);
          setRightChild(response?.data?.right || null);
          setRightGrandChildren(response?.data?.right || []);
          setLeftGrandChildren(response?.data?.left || []);
          toast.success(t("_NETWORK._SUCCESS"));
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const getDirects = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/directs`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        setLeftDirects(response?.left)
        setRighDirects(response?.right)
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const getPackageImage = (binary) => {
    switch (binary) {
      case '1':
        return Package500;
      case '2':
        return Package1000;
      case '3':
        return Package2000;
      case '4':
        return Package4000;
      case '5':
        return Package8000;
      case '6':
        return Package16000;
      case '7':
        return Package32000;
      case '8':
        return Package64000;
      default:
        return Packagenull;
    }
  };
  return (
    <div className="container mx-auto h-screen">
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 p-4">
        <li className="me-2">
          <div
            className={`cursor-pointer inline-block px-4 py-3 rounded-lg ${tab === 'network' ? 'text-white bg-gladiaverse' : 'hover:text-white hover:bg-gray-600'}`}
            onClick={() => setTab('network')}
          >
            {t("_NETWORK._NETWORK")}
          </div>
        </li>
        <li className="me-2">
          <div
            className={`cursor-pointer inline-block px-4 py-3 rounded-lg ${tab === 'directs' ? 'text-white bg-gladiaverse' : 'hover:text-white hover:bg-gray-600'}`}
            onClick={() => setTab('directs')}
          >
            {t("_NETWORK._DIRECTS")}
          </div>
        </li>
      </ul>
      {tab === "network" && (
        <>
          <div className="flex flex-col lg:flex-row gap-4 p-4">
            <div className="w-full lg:w-1/2 bg-gladiaverse rounded-lg shadow-md text-white p-4 space-y-2">
              <h4> {t("_NETWORK._SEARCHLABEL")}</h4>
              <form>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input type="email" id="search" className="block w-full p-4 ps-10 text-sm text-white rounded-lg bg-gray-800 focus:ring-blue-500 focus:border-blue-500 text-white" placeholder="search@mail.com" required onChange={e => setSearchEmail(e.target.value)} />
                  <button type="button" className="text-white absolute end-2.5 bottom-2.5 bg-violet-700 hover:bg-violet-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2" onClick={() => getUsersDataEmail()}> {t("_NETWORK._SEARCH")}</button>
                </div>
              </form>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4 p-4">
            <div className="w-full lg:w-1/2 bg-gladiaverse rounded-lg shadow-md text-white p-4">
              <h4>{t("_NETWORK._LEFT")}</h4>
              <p>{t("_NETWORK._AVAILABLE")}: {dataUser?.balance_binary_left || 0} TRX</p>
              <p>{t("_NETWORK._WITHDRAW")}: {dataUser?.total_binary_left || 0} TRX</p>
            </div>
            <div className="w-full lg:w-1/2 bg-gladiaverse rounded-lg shadow-md text-white p-4">
              <h4>{t("_NETWORK._RIGHT")}</h4>
              <p>{t("_NETWORK._AVAILABLE")}: {dataUser?.balance_binary_right || 0} TRX</p>
              <p>{t("_NETWORK._WITHDRAW")}: {dataUser?.total_binary_right || 0} TRX</p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4 p-4">
            <div className="flex flex-col items-center space-y-8 bg-gladiaverse lg:pt-10 lg:pb-20 py-10 w-full rounded-lg shadow-md">
              <div className="flex justify-center flex-col relative w-36 group my-10 lg:mt-10 lg:my-10 cursor-pointer">
                <img
                  className={`w-32 h-32 mx-auto z-40 ${dataUser ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10 hidden'}`}
                  src={getPackageImage(dataUser?.package)}
                  onClick={() => getUsersData(dataUser?.sponsor_id)}
                  alt={dataUser?.name}
                />
                <img src={Coliseo} className={`absolute ${dataUser ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} alt="Coliseo" />
                <p className="text-white absolute -top-8 text-lg left-1/2 transform -translate-x-1/2 truncate max-w-full">{dataUser?.name?.length > 30 ? dataUser.name.substring(0, 30) + '...' : dataUser?.name}</p>
              </div>
              <div className="flex flex-col w-full justify-center pb-20 my-20 ">
                <div className="flex flex-col items-center justify-center lg:flex-row lg:justify-around lg:space-x-8 w-full space-y-24 lg:space-y-0">
                  <div className="flex justify-around flex-col relative w-36 group cursor-pointer">
                    <img className={`w-32 h-32 mx-auto z-40 ${leftChild ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10 '}`}
                      src={getPackageImage(leftChild?.package)}
                      onClick={() => getUsersData(leftChild?.id || null)} alt={dataUser?.name} />
                    <img src={Coliseo} className={`absolute ${leftChild ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} alt="Coliseo" />
                    <p className="text-white opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity absolute -bottom-24 left-1/2 transform -translate-x-1/2 "> {leftChild?.name ? leftChild?.name : ""} <span className="block lg:hidden text-blue-200 uppercase"> {t("_NETWORK._LEFT")}</span></p>
                  </div>
                  <div className="flex justify-around flex-col relative w-36 group cursor-pointer">
                    <img className={`w-32 h-32 mx-auto z-40 ${rightChild ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`}
                      src={getPackageImage(rightChild?.package)}
                      onClick={() => getUsersData(rightChild?.id || null)} alt={dataUser?.name} />
                    <img src={Coliseo} className={`absolute ${rightChild ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} alt="Coliseo" />
                    <p className="text-white opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity absolute -bottom-24 left-1/2 transform -translate-x-1/2">{rightChild?.name ? rightChild?.name : ""} <span className="block lg:hidden text-blue-200 uppercase"> {t("_NETWORK._RIGHT")}</span></p>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-center pb-20 my-20">
                <div className="flex flex-col items-center  lg:flex-row lg:justify-around lg:space-x-8 w-full space-y-24 lg:space-y-0">
                  <div className="flex justify-around  flex-col relative w-36 group cursor-pointer">
                    <img className={`w-32 h-32  mx-auto z-40 ${leftGrandChildren?.left_child ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} src={getPackageImage(leftGrandChildren?.left_child?.package)} alt={dataUser?.name} onClick={() => getUsersData(leftGrandChildren?.left_child?.id || null)} />
                    <img src={Coliseo} className={`absolute ${leftGrandChildren?.left_child ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} alt="Coliseo" />
                    <p className="text-white opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity absolute -bottom-24 left-1/2 transform -translate-x-1/2"> {leftGrandChildren?.left_child?.name || ""} <span className="block lg:hidden text-blue-200 uppercase"> {t("_NETWORK._LEFT")}</span></p>
                  </div>
                  <div className="flex justify-around  flex-col relative w-36 group cursor-pointer" >
                    <img className={`w-32 h-32  mx-auto z-40 ${leftGrandChildren?.right_child ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} src={getPackageImage(leftGrandChildren?.right_child?.package)} alt={dataUser?.name} onClick={() => getUsersData(leftGrandChildren?.right_child?.id || null)} />
                    <img src={Coliseo} className={`absolute ${leftGrandChildren?.right_child ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} alt="Coliseo" />
                    <p className="text-white opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity absolute -bottom-24 left-1/2 transform -translate-x-1/2">{leftGrandChildren?.right_child?.name || ""} <span className="block lg:hidden text-blue-200 uppercase"> {t("_NETWORK._RIGHT")}</span></p>
                  </div>
                  <div className="flex justify-around  flex-col relative w-36 group cursor-pointer">
                    <img className={`w-32 h-32 mx-auto z-40 ${rightGrandChildren?.left_child ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} src={getPackageImage(rightGrandChildren?.left_child?.package)} alt={dataUser?.name} onClick={() => getUsersData(rightGrandChildren?.left_child?.id || null)} />
                    <img src={Coliseo} className={`absolute ${rightGrandChildren?.left_child ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} alt="Coliseo" />
                    <p className="text-white opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity absolute -bottom-24 left-1/2 transform -translate-x-1/2">{rightGrandChildren?.left_child?.name || ""} <span className="block lg:hidden text-blue-200 uppercase"> {t("_NETWORK._LEFT")}</span></p>
                  </div>
                  <div className="flex justify-around  flex-col relative w-36 group cursor-pointer">
                    <img className={`w-32 h-32 mx-auto z-40 ${rightGrandChildren?.right_child ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} src={getPackageImage(rightGrandChildren?.right_child?.package)} alt={dataUser?.name} onClick={() => getUsersData(rightGrandChildren?.right_child?.id || null)} />
                    <img src={Coliseo} className={`absolute ${rightGrandChildren?.right_child ? '-bottom-11 opacity-90 hover:opacity-100 ' : 'opacity-10 -bottom-10'}`} alt="Coliseo" />
                    <p className="text-white opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity absolute -bottom-24 left-1/2 transform -translate-x-1/2">{rightGrandChildren?.right_child?.name || ""} <span className="block lg:hidden text-blue-200 uppercase"> {t("_NETWORK._RIGHT")}</span></p>
                  </div>
                </div>
                {/* 
            <div className="flex justify-center my-5" >
              <div className="line-group line-group" style={{ height: '2px', width: '50%' }}></div>
            </div>
            <div className="flex justify-center my-5" >
              <div className="line-group line-group" style={{ height: '2px', width: '50%' }}></div>
            </div>
            */}
              </div>
            </div>
          </div>
        </>
      )}
      {tab === "directs" && (
        <>
          <div className="flex flex-col lg:flex-row gap-4 p-4">
            <div className="flex flex-col items-center space-y-8 bg-gladiaverse lg:pt-10 lg:pb-20 py-10 w-full rounded-xl shadow-md p-4">
              <table className="min-w-full bg-gladiaverse">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-white">
                      <h3>{t("_NETWORK._LEFT")}</h3>
                    </th>
                    <th className="py-2 px-4 text-white">
                      <h3>{t("_NETWORK._RIGHT")}</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lefttDirects.map((item, index) => (
                    <tr key={`left-${index}`}>
                      <td className="border border-slate-400 px-4 py-2 text-slate-400 rounded-xl">
                        {item.name} - {item?.email} - {t("_PACKAGES._PACKAGE")} GV{item?.package}
                      </td>
                      <td className="border px-4 py-2 text-slate-400">
                        {rightDirects[index]?.name || ''} -  {rightDirects[index]?.email || ''}  {rightDirects[index]?.package ? "-  Package GV " + rightDirects[index]?.package : ''}
                      </td>
                    </tr>
                  ))}
                  {rightDirects.slice(lefttDirects.length).map((item, index) => (
                    <tr key={`right-${index}`}>
                      <td className="border border-slate-400 px-4 py-2 text-slate-400 rounded-xl">
                        &nbsp;
                      </td>
                      <td className="border px-4 py-2 text-slate-400">
                        {item.name} - {item?.email} - {t("_PACKAGES._PACKAGE")} GV{item?.package}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        </>
      )}
    </div>
  );
}
