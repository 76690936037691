import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";

export default function Arbitrage() {
  const { t } = useTranslation();
  const [opportunities, setOpportunities] = useState([]);
  const [animatedIndices, setAnimatedIndices] = useState([]);
  const { loading, updateLoading } = useContext(AppContext);

  const fetchOpportunities = async () => {
    try {
      updateLoading(true);
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 120000); // 2 minutos

      const response = await fetch('https://ak8jn40xah.execute-api.us-west-1.amazonaws.com/default/gladiaverse-show-arbitrage', {
        signal: controller.signal
      });

      if (!response.ok) {
        updateLoading(false);
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      clearTimeout(timeoutId);
      updateLoading(false);
      return data;
    } catch (error) {
      updateLoading(false);
      if (error.name === 'AbortError') {
        console.error('Fetch timed out:', error);
      } else {
        console.error('Error fetching arbitrage opportunities:', error);
      }
      return [];
    }
  };

  useEffect(() => {
    const getOpportunities = async () => {
      const data = await fetchOpportunities();
      setOpportunities(data);
    };

    getOpportunities();
    const intervalId = setInterval(getOpportunities, 21000); // 21 segundos

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const animateRows = () => {
      const indices = Array.from({ length: opportunities.length }, (_, index) => index);
      const randomIndices = indices.sort(() => 0.5 - Math.random()).slice(0, Math.floor(opportunities.length / 20));
      setAnimatedIndices(randomIndices);

      setTimeout(() => {
        setAnimatedIndices([]);
      }, 3000);
    };

    const animationInterval = setInterval(animateRows, 2000); // Cambiar filas cada 2 segundos

    // Cleanup interval on unmount
    return () => clearInterval(animationInterval);
  }, [opportunities]);

  return (
    <div className="container mx-auto p-4">
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 p-4">
        <li className="me-2">
          <a href="https://gladiaverse.io/profitdetail" target={"_blank"} rel="noreferrer" >
            <div
              className={`cursor-pointer inline-block px-4 py-3 rounded-lg  text-white bg-gladiaverse hover:text-white hover:bg-gray-600`}
            >
              Network
            </div>
          </a>
        </li>
      </ul>
      <h1 className="text-2xl font-bold mb-4 text-white">Arbitrage Opportunities</h1>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gladiaverse text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Crypto</th>
              <th scope="col" className="px-6 py-3">Buy Exchange</th>
              <th scope="col" className="px-6 py-3">Buy Price</th>
              <th scope="col" className="px-6 py-3">Sell Exchange</th>
              <th scope="col" className="px-6 py-3">Sell Price</th>
              <th scope="col" className="px-6 py-3">Difference</th>
              <th scope="col" className="px-6 py-3">Percentage Difference</th>
            </tr>
          </thead>
          <tbody>
            {opportunities.map((opportunity, index) => (
              <tr
                className={`border-b bg-gladiaverse border-gray-700 opacity-90 hover:opacity-100 `}
                key={index}
              >
                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                  {opportunity.crypto}
                </th>
                <td className={`px-6 py-4 `}>
                  {opportunity.buyExchange}
                </td>
                <td className={`px-6 py-4 ${animatedIndices.includes(index) ? 'bg-green-800' : ''}`}>
                  {opportunity.buyPrice}
                </td>
                <td className={`px-6 py-4 `}>
                  {opportunity.sellExchange}
                </td>
                <td className={`px-6 py-4 ${animatedIndices.includes(index) ? 'bg-red-800' : ''}`}>{opportunity.sellPrice}</td>
                <td className="px-6 py-4">{opportunity.difference}</td>
                <td className={`px-6 py-4 ${animatedIndices.includes(index) ? 'text-green-500' : ''}`}>
                  {opportunity.percentageDifference.toFixed(2)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
